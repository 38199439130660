import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { map, Observable, of } from 'rxjs';
import { CarLotModel } from 'src/app/model/global/car-lot.model';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';
import { VehicleListFilter } from 'src/app/model/vehicle/vehicle-list.filter';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  manufacturerList: SelectionItem[] = [];
  mileageRanges = [
    { id: 1, name: "<50 000", from: undefined, to: 50000},
    { id: 2, name: "50 000 - 100 000", from: 50000, to: 100000},
    { id: 3, name: "100 000 - 150 000", from: 100000, to: 150000},
    { id: 4, name: "150 000 - 200 000", from: 150000, to: 200000},
    { id: 5, name: "200 000 - 250 000", from: 200000, to: 250000},
    { id: 6, name: ">250 000", from: 250000, to: undefined},
  ]
  yearRanges = [
    {id: 2010, name: "2010"},
    {id: 2015, name: "2015"},
    {id: 2020, name: "2020"},
  ]
  priceRanges = [
    {id: 5000, name: "5 000"},
    {id: 10000, name: "10 000"},
    {id: 15000, name: "15 000"},
    {id: 20000, name: "20 000"},
    {id: 25000, name: "25 000"},
    {id: 30000, name: "30 000"},
  ]

  @Input() filter!: VehicleListFilter;
  @Output() filterChange = new EventEmitter<VehicleListFilter>();

  faSearch = faSearch;

  constructor(
    public globalService: GlobalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.globalService.manufacturerModelList$.subscribe(m => {
      this.manufacturerList = m.map(x => ({id: x.id, name: x.name} as SelectionItem))
    });
  }

  searchClicked() {
    this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
  }


  manufacturerChange(id: any) {
    this.filter.manufacturerId = id;
    this.filterChange.emit(this.filter);
  }
  
  modelChange(id: any) {
    this.filter.modelId = id;
    this.filterChange.emit(this.filter);
  }

  mileageChange(id: number) {
    let selections = this.mileageRanges.filter(r => r.id == id);
    if (!selections || selections.length < 1) return; 

    let selection = selections[0];

    this.filter.mileageFrom = selection.from;
    this.filter.mileageTo = selection.to;
    this.filterChange.emit(this.filter);
  }

  // yearChange(id: number) {
  //   let selections = this.yearRanges.filter(r => r.id == id);
  //   if (!selections || selections.length < 1) return; 

  //   let selection = selections[0];

  //   this.filter.yearFrom = selection.from;
  //   this.filter.yearTo = selection.to;
  //   this.filterChange.emit(this.filter);
  // }

  // priceChange(id: number) {
  //   let selections = this.priceRanges.filter(r => r.id == id);
  //   if (!selections || selections.length < 1) return; 

  //   let selection = selections[0];

  //   this.filter.priceTo = selection.id;
  //   this.filterChange.emit(this.filter);
  // }


  selectedManufacturerModels(): Observable<SelectionItem[]> {
    if (this.filter.manufacturerId && this.filter.manufacturerId > 0) {
      return this.globalService.manufacturerModelList$.pipe(
        map(m => m.filter(x => x.id == this.filter.manufacturerId)[0].models)
      );
    }

    return of([]);
  }

  carLotChange(id: number) {
    this.filter.carLotId = id;
    this.filterChange.emit(this.filter);
  }

  transmissionChange(id: number) {
    this.filter.transmisionType = id;
    this.filterChange.emit(this.filter);
  }

  fuleTypeChange(id: number) {
    this.filter.fuelType = id;
    this.filterChange.emit(this.filter);
  }

  minPriceChange(val: any) {
    this.filter.priceFrom = val;
    this.filterChange.emit(this.filter);
  }

  maxPriceChange(val: any) {
    this.filter.priceTo  = val;
    this.filterChange.emit(this.filter);
  }

  minYearChange(val: any) {
    this.filter.yearFrom = val;
    this.filterChange.emit(this.filter);
  }

  maxYearChange(val: any) {
    this.filter.yearTo  = val;
    this.filterChange.emit(this.filter);
  }
}
