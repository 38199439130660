<div class="d-inline-block w-100 position-relative" (clickOutside)="closeDropdown($event)">
    <button class="dropdown-button"
        [ngClass]="{
            'dropdown-button-active': dropdownOpen,
            'dropdown-button-disabled': disabled
        }"
        (click)="openDropdown($event)"
        [disabled]="disabled">

        <div class="w-100 d-flex justify-content-between">
            <div *ngIf="selectedItem"> <fa-icon [icon]="faTimesCircle" (click)="clearClicked($event)"></fa-icon> {{selectedItem.name | translate }}</div>
            <div *ngIf="!selectedItem">{{placeholder | translate }}</div>

            <fa-icon *ngIf="!dropdownOpen" [icon]="faChevronDown" (click)="openDropdown($event)"></fa-icon>
            <fa-icon *ngIf="dropdownOpen" [icon]="faChevronUp" (click)="closeDropdown($event)"></fa-icon>
        </div>
    </button>
    <ul #dropdownList class="dropdown-list" role="listbox" aria-expanded="false" [ngClass]="{'dropdown-open' : dropdownOpen}">
        <li *ngFor="let o of list"
            [ngClass]="{'selected' : selectedItem === o}"
            tabindex="0"
            (click)="select(o.id)"
            role="option">{{o.name | translate}}</li>
    </ul>
</div>