import { Component, Input, OnInit } from '@angular/core';
import { faRoad, faCheckCircle, faBolt, faGasPump, faCogs, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faImages } from '@fortawesome/free-regular-svg-icons';

import { VehicleListModel } from 'src/app/model/vehicle/vehicle-list.dto';
import { GlobalService } from 'src/app/services/global.service';
import { BASE_ADDR } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalGalleryComponent } from '../modal-gallery/modal-gallery.component';

@Component({
  selector: 'app-car-card',
  templateUrl: './car-card.component.html',
  styleUrls: ['./car-card.component.css']
})
export class CarCardComponent implements OnInit {
  BASE_ADDR = BASE_ADDR;
  
  @Input('vehicle')
  vehicle!: VehicleListModel;

  faRoad = faRoad;
  faCheckCircle = faCheckCircle;
  faBolt = faBolt;
  faGasPump = faGasPump;
  faCogs = faCogs;
  faMapMarkerAlt = faMapMarkerAlt;
  faCalendar = faCalendar;
  faImages = faImages;
  
  link: string = "";
  showModal: boolean = false;


  constructor(
    public globalService: GlobalService,
    private modalService: SimpleModalService) { }

  ngOnInit(): void {
    if (this.vehicle) {
      this.link = this.vehicle.title.toLowerCase().replace(/[\s.]/g, '-').replace(/[^a-z0-9\-]/g, '') + `-${this.vehicle.id}`;
    }
  }


  showGallery() {
    this.modalService.addModal(ModalGalleryComponent, {
      images: this.vehicle.images
    })
  }
}
