<div class="card w-100 text-left">
  <div class="card-body text-left">
    <h4 class="text-left font-weight-semi-bold">{{ 'Component_LoanCalculator_Title' | translate }}</h4>
    <p class="primary-gray">{{ 'Component_LoanCalculator_Description' | translate }}</p>
  
    <div class="d-flex justify-content-between payment-wrapper">
      <div class="d-flex flex-column mt-auto">
        <span class="font-weight-semi-bold d-flex"
          [ngClass]="{'loan-value-bold': monthlyPayment <= residualValuePayment, 'loan-value-regular': monthlyPayment > residualValuePayment}">
          <span class="primary-gray h4 mt-auto mb-auto font-weight-semi-bold">€</span>
          {{ monthlyPayment | number:'1.0-0' }}
        </span>
        <span class="primary-gray text-center payment-label">
          {{ 'Component_LoanCalculator_MonthlyPayment' | translate }}
        </span>
      </div>
      <div class="d-flex flex-column mt-auto">
        <span class="font-weight-semi-bold d-flex"
          [ngClass]="{'loan-value-bold': monthlyPayment > residualValuePayment, 'loan-value-regular': monthlyPayment <= residualValuePayment}">
          <span class="primary-gray h4 mt-auto mb-auto font-weight-semi-bold">€</span>
          {{ residualValuePayment | number:'1.0-0' }}
        </span>
        <span class="primary-gray text-center payment-label">
          {{ 'Component_LoanCalculator_ResidualPayment' | translate }}
          <span [attr.data-text]="'Component_LoanCalculator_FAQLink' | translate" class="simple-tooltip">
            <sup role="button"
              [routerLink]="['/', 'faq']"
              [fragment]="'loan-residual-payment'">
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
            </sup>
          </span>
        </span>
      </div>
    </div>
  
    <div class="mt-3">
      <label class="font-weight-medium">{{ 'Component_LoanCalculator_DownPayment' | translate }}</label>
      
      <app-fat-slider [(value)]="downPaymentValue"
        (valueChange)="onDownPaymentChange($event)"
        [min]="downPaymentOptions.floor"
        [max]="downPaymentOptions.ceil"
        [translate]="downPaymentOptions.translate">
      </app-fat-slider>
      <!-- <ngx-slider [(value)]="downPaymentValue" (valueChange)="onDownPaymentChange($event)" [options]="downPaymentOptions"></ngx-slider> -->
    </div>
      
    <div class="mt-3">
      <label class="font-weight-medium">{{ 'Component_LoanCalculator_Period' | translate }}</label>
      
      <app-fat-slider [(value)]="periodValue"
        (valueChange)="onPeriodChange($event)"
        [min]="periodOptions.floor"
        [max]="periodOptions.ceil"
        [step]="periodOptions.step"
        [translate]="periodOptions.translate">
      </app-fat-slider>
      <!-- <ngx-slider [(value)]="periodValue" (valueChange)="onPeriodChange($event)" [options]="periodOptions"></ngx-slider> -->
    </div>
  
    <p class="tiny lh-100 mt-3 text-justify">{{ 'Component_LoanCalculator_Disclaimer' | translate }}</p>
  </div>
</div>