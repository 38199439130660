import { Component, Input, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';
import { GlobalService } from 'src/app/services/global.service';
import { faCheck, faCheckCircle, faRoad, faBolt, faGasPump, faCogs } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css']
})
export class FeaturedComponent implements OnInit {
  kwToHpRatio: number = 1.36;

  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faRoad = faRoad;
  faBolt = faBolt;
  faGasPump = faGasPump;
  faCogs = faCogs;
  faCalendar = faCalendar;

  @Input() vehicle: VehicleModel;

  constructor(
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
