import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';
import { LINQUtil } from '../../util/linq.util';
import { faChevronDown, faChevronUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dual-dropdown',
  templateUrl: './dual-dropdown.component.html',
  styleUrls: ['./dual-dropdown.component.scss']
})
export class DualDropdownComponent implements OnInit {

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTimesCircle = faTimesCircle;

  @Input('list')
  list: SelectionItem[] = [];
  
  @Output('minSelection')
  minSelection = new EventEmitter<number>();
  @Output('maxSelection')
  maxSelection = new EventEmitter<number>();
  @Input('minValue')
  set minValue(value: number|undefined) {
    if (value) {
      if (typeof value != "number") value = parseInt(value);
      this.minItemSelected(value, undefined);
    }
  }  
  @Input('maxValue')
  set maxValue(value: number|undefined) {
    if (value) {
      if (typeof value != "number") value = parseInt(value);
      this.maxItemSelected(value, undefined);
    }
  }
  @Input('placeholder')
  placeholder?: string = undefined;

  minId?: number = undefined;
  minItem?: SelectionItem = undefined;
  maxId?: number = undefined;
  maxItem?: SelectionItem = undefined;

  dropdownOpen: boolean = false;
  disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // Selection
  minItemSelected(id: number, e: any) {
    e?.stopPropagation();
    if (this.maxId == undefined || id <= this.maxId) {
      this.minId = id;
      this.minItem = LINQUtil.firstOrDefault(this.list, i => i.id == id);
      this.minSelection.emit(id);
    }
  }

  maxItemSelected(id: number, e: any) {
    e?.stopPropagation();
    if (this.minId == undefined || id >= this.minId) {
      this.maxId = id;
      this.maxItem = LINQUtil.firstOrDefault(this.list, i => i.id == id);
      this.maxSelection.emit(id);
    }
  }

  // Clearing
  clearMin() {
    this.minId = undefined;
    this.minItem = undefined;
    this.minSelection.emit(undefined);
  }
  
  clearMax() {
    this.maxId = undefined;
    this.maxItem = undefined;
    this.maxSelection.emit(undefined);
  }
  
  openDropdown(event: MouseEvent) {
    if (!this.disabled) {
      event.stopPropagation();
      this.dropdownOpen = true;
    }
  }

  closeDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownOpen = false;
  }
}
