<div class="d-flex flex-column">
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Make' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown 
            [list]="manufacturerList"
            [value]="filter.manufacturerId"
            (selection)="manufacturerChange($event)"
            [placeholder]="'Component_SidebarFilter_Placeholder_Make' | translate">
        </app-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Model' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown 
            [disabled]="!filter.manufacturerId"
            [list]="selectedManufacturerModels() | async"
            [value]="filter.modelId"
            (selection)="modelChange($event)"
            [placeholder]="'Component_SidebarFilter_Placeholder_Model' | translate">
        </app-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Price' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dual-dropdown
            [list]="priceRanges"
            [minValue]="filter.priceFrom"
            [maxValue]="filter.priceTo"
            (minSelection)="minPriceChange($event)"
            (maxSelection)="maxPriceChange($event)">
        </app-dual-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Year' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dual-dropdown
            [list]="yearRanges"
            [minValue]="filter.yearFrom"
            [maxValue]="filter.yearTo"
            (minSelection)="minYearChange($event)"
            (maxSelection)="maxYearChange($event)">
        </app-dual-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Mileage' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dual-dropdown
            [list]="mileageRanges"
            [minValue]="filter.mileageFrom"
            [maxValue]="filter.mileageTo"
            (minSelection)="minMileageChange($event)"
            (maxSelection)="maxMileageChange($event)">
        </app-dual-dropdown>
    </div>
    
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_FuelType' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown
            [list]="globalService.fuelTypes$ | async"
            [value]="filter.fuelType"
            (selection)="fuleTypeChange($event)"
            [placeholder]="'Component_Filter_Placeholder_FuelType' | translate">
        </app-dropdown>
    </div>

    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Transmission' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown 
            [list]="globalService.gearboxTypes$ | async"
            [value]="filter.transmisionType"
            (selection)="transmissionChange($event)"
            [placeholder]="'Component_SidebarFilter_Placeholder_Transmission' | translate">
        </app-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Power' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dual-dropdown
            [list]="powerRanges"
            [minValue]="filter.powerFrom"
            [maxValue]="filter.powerTo"
            (minSelection)="minPowerChange($event)"
            (maxSelection)="maxPowerChange($event)">
        </app-dual-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_Type' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown
            [list]="globalService.vehicleClassifiers$ | async"
            [value]="filter.vehicleType"
            (selection)="vehicleTypeChange($event)"
            [placeholder]="'Component_SidebarFilter_Placeholder_Type' | translate">
        </app-dropdown>
    </div>
    
    <div class="mt-1 mb-1">
        <small class="accent font-weight-semi-bold">{{ 'Component_SidebarFilter_Label_CarLot' | translate }}</small>
    </div>
    <div class="mt-1 mb-1">
        <app-dropdown
            [list]="globalService.carLots$ | async"
            [value]="filter.carLotId"
            (selection)="carLotChange($event)"
            [placeholder]="'Component_SidebarFilter_Placeholder_CarLot' | translate">
        </app-dropdown>
    </div>

    
    <div class="mt-1 mb-1">
        <app-button [icon]="faSearch" (click)="onSearchClicked()">
            {{ 'Component_SidebarFilter_Search' | translate }}
        </app-button>
    </div>
    
</div>