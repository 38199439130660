<div class="sidemenu-language d-block d-xl-none">
    <div class="d-inline-block lang-selection h-100 pl-3 pr-3">
        <button class="selected-language w-100 h-100 mr-2" id="dropdownManual" (click)="toggleDropdown($event)">
            <div class="d-flex justify-content-between">
                <span class="flag-icon" [ngClass]="selectedLangIcon"></span>
                {{selectedLangName}}
            </div>
        </button>
        <div>

        <div class="dropdown-list" [ngClass]="{'dropdown-open' : dropdownOpen}">
            <ul class="p-1 mb-0" role="listbox" aria-expanded="false">
                <div (click)="selectLang('lt')" class="language-selection-item w-100 d-flex"><button class="ml-1"><span class="flag-icon flag-icon-lt mr-1"></span> Lietuvių</button></div>
                <div (click)="selectLang('en')" class="language-selection-item w-100 d-flex"><button class="ml-1"><span class="flag-icon flag-icon-gb mr-1"></span> English</button></div>
                <!-- <div class="language-selection-item w-100 d-flex"><button class="m-auto"><span class="flag-icon flag-icon-ru flag-icon-squared rounded-circle mr-1"></span></button></div> -->
            </ul>
        </div>
    </div>
</div>