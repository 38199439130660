import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';
import { VehicleListFilter } from 'src/app/model/vehicle/vehicle-list.filter';
import { GlobalService } from 'src/app/services/global.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.css']
})
export class SidebarFilterComponent implements OnInit {
  faSearch = faSearch;

  manufacturerList: SelectionItem[] = [];
  priceRanges: SelectionItem[] = [
    {id: 5000, name: "5 000"},
    {id: 10000, name: "10 000"},
    {id: 15000, name: "15 000"},
    {id: 20000, name: "20 000"},
    {id: 25000, name: "25 000"},
    {id: 30000, name: "30 000"},
  ]
  mileageRanges: SelectionItem[] = [
    {id:50000, name: "50 000"},
    {id:100000, name: "100 000"},
    {id:150000, name: "150 000"},
    {id:200000, name: "200 000"},
    {id:250000, name: "250 000"},
  ]
  yearRanges: SelectionItem[] = [
    {id:2010, name: "2010"},
    {id:2015, name: "2015"}, 
    {id:2020, name: "2020"},
  ]
  powerRanges: SelectionItem[] = [
    {id:50, name: "50"},
    {id:100, name: "100"},
    {id:150, name: "150"},
    {id:200, name: "200"},
  ]

  @Input() filter!: VehicleListFilter;
  @Output() searchClicked = new EventEmitter();

  constructor(
    public globalService: GlobalService,
    private router: Router
  ) {
    this.globalService.manufacturerModelList$.subscribe(m => {
      this.manufacturerList = m.map(x => ({id: x.id, name: x.name} as SelectionItem))
    });}

  ngOnInit(): void {
  }

  onSearchClicked() {
    this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    this.searchClicked.emit();
  }
  
  selectedManufacturerModels(): Observable<SelectionItem[]> {
    if (this.filter.manufacturerId && this.filter.manufacturerId > 0) {
      return this.globalService.manufacturerModelList$.pipe(
        map(m => m.filter(x => x.id == this.filter.manufacturerId)[0].models)
      );
    }

    return of([]);
  }


  
  manufacturerChange(id: any) {
    if (this.filter.manufacturerId != id) {
      this.filter.manufacturerId = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }
  
  modelChange(id: any) {
    if (this.filter.modelId != id) {
      this.filter.modelId = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  minPriceChange(val: any) {
    if (this.filter.priceFrom != val) {
      this.filter.priceFrom = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  maxPriceChange(val: any) {
    if (this.filter.priceTo != val) {
      this.filter.priceTo  = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  minYearChange(val: any) {
    if (this.filter.yearFrom != val) {
      this.filter.yearFrom = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  maxYearChange(val: any) {
    if (this.filter.yearTo != val) {
      this.filter.yearTo  = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  minMileageChange(val: any) {
    if (this.filter.mileageFrom != val) {
      this.filter.mileageFrom = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  maxMileageChange(val: any) {
    if (this.filter.mileageTo != val) {
      this.filter.mileageTo  = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  transmissionChange(id: any) {
    if (this.filter.transmisionType != id) {
      this.filter.transmisionType = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  vehicleTypeChange(id: any) {
    if (this.filter.vehicleType != id) {
      this.filter.vehicleType = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  minPowerChange(val: any) {
    if (this.filter.powerFrom != val) {
      this.filter.powerFrom = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  maxPowerChange(val: any) {
    if (this.filter.powerTo != val) {
      this.filter.powerTo  = val;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  fuleTypeChange(id: number) {
    if (this.filter.fuelType != id) {
      this.filter.fuelType = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }

  carLotChange(id: number) {
    if (this.filter.carLotId != id) {
      this.filter.carLotId = id;
      this.filter.vehicleStateId = null;
      this.filter.page = 1;
      this.router.navigate(["/search"], {queryParams: this.filter.customParams()});
    }
  }
}
