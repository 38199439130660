import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';
import { faChevronDown, faChevronUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  faTimesCircle = faTimesCircle;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  @Input('placeholder')
  placeholder: string = "";
  @Input('list')
  list: SelectionItem[] | null = [];
  @Input('disabled')
  disabled: boolean = false;
  @Output('selection')
  selectionChange = new EventEmitter<number>();
  cleared: any;

  @Input('value')
  set value(value: number|undefined) {
    if (value) {
      if (typeof value != "number") this.select(parseInt(value));
      else this.select(value);
    }
  }

  @ViewChild('ddlButton') dropdownButton!: ElementRef;


  selectedId?: number;
  selectedItem?: SelectionItem = undefined;

  dropdownOpen: boolean = false;

  constructor(
    private elem: ElementRef
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["list"] 
      && (changes["list"].previousValue == undefined || changes["list"].previousValue.length == 0)
      && changes["list"].currentValue != undefined && changes["list"].currentValue.length > 0
      && this.selectedId !== undefined) {
      
        this.selectedItem = this.list!.filter(i => i.id == this.selectedId)[0];
    }
  }

  clearClicked(e: any) {
    e.stopPropagation();
    
    this.selectedId = undefined;
    this.selectedItem = undefined;
    this.selectionChange.emit(undefined);
  }

  openDropdown(event: MouseEvent) {
    if (!this.disabled) {
      event.stopPropagation();
      this.dropdownOpen = true;
    }
  }

  closeDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownOpen = false;
  }

  
  select(id) {
    this.selectedId = id;
    if (this.list) {
      this.selectedItem = this.list!.filter(i => i.id == id)[0];
      this.dropdownOpen = false;
      this.selectionChange.emit(this.selectedId);
    }
  }
}
