import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationModel } from "../model/global/notification-model";
import { FaqGroupModel } from "../model/static/faq-group.model";
import { AsyncApiCallHelperService, HttpService } from "./http.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends HttpService {
    private path: string = "/api/notification/";

    constructor(protected http: HttpClient, helper: AsyncApiCallHelperService) {
      super(http, helper);
    }
  
    getNotification(): Observable<NotificationModel> {
      return this.get<NotificationModel>(this.path + "active");
    }
}