import { Component, Input, OnInit, Output } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { EventEmitter } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  @Input()
  page: number = 1;

  @Input()
  perPage: number = 20;

  @Input()
  totalCount: number = 20;

  @Output()
  nextClicked = new EventEmitter();

  @Output()
  prevClicked = new EventEmitter();


  get totalPageCount(): number {
    if (!this.perPage) return 0;

    return Math.ceil(this.totalCount / this.perPage);
  }

  constructor(
    private scroll: ViewportScroller
  ) { }

  ngOnInit(): void {

  }


  onPrevClick() {
    this.prevClicked.emit();
    this.scroll.scrollToPosition([0,0])
  }

  onNextClick() {
    this.nextClicked.emit();
    this.scroll.scrollToPosition([0,0])
  }

}
