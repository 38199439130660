import { Component, OnInit } from '@angular/core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile-language-selection',
  templateUrl: './mobile-language-selection.component.html',
  styleUrls: ['./mobile-language-selection.component.scss']
})
export class MobileLanguageSelectionComponent implements OnInit {


  faChevronUp = faChevronUp;

  selectedLangIcon: string = "gb";
  selectedLangName: string = "English";
  subscription?: Subscription = undefined;
  
  dropdownOpen: boolean = false;

  constructor(
    private translate: TranslateService
  ) {
    this.subscription = this.translate.onLangChange.subscribe(e => {
      this.selectedLangIcon = 'flag-icon-'+this.getLang();
      this.selectedLangName = this.getLangName();
    })
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
  }

  ngOnInit(): void {
  }

  selectLang(lang: string) {
    this.translate.use(lang);
    this.closeDropdown(undefined);
  }

  getLang(): string {
    switch(this.translate.currentLang) {
      case 'lt': return 'lt';
      case 'en': return 'gb';
    }

    return 'gb';
  }

  getLangName(): string {
    switch(this.translate.currentLang) {
      case 'lt': return 'Lietuvių';
      case 'en': return 'English';
    }

    return 'English';
  }

  toggleDropdown(event: MouseEvent) {
    event?.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }

  openDropdown(event: MouseEvent) {
    event?.stopPropagation();
    this.dropdownOpen = true;
  }

  closeDropdown(event: MouseEvent) {
    event?.stopPropagation();
    this.dropdownOpen = false;
  }
}
