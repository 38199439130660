<div class="container-fluid filter-background">
    <div class="container pt-5 pb-5 ">
        <div class="row pt-5 pb-5 no_vertical_padding_small">
            <div class="col-12 col-xl-7 nopadding">
                <div class="card">
                    <div class="card-body p-4">

                        <h1 class="font-weight-semi-bold">{{ 'Component_Filter_FindYour' | translate }} <span class="accent">{{ 'Component_Filter_Perfect' | translate }}</span> {{ 'Component_Filter_Car' | translate }}.</h1>
                            <!-- <span class="d-none d-xl-inline"> {{ 'Component_Filter_ChooseDeliver' | translate }}</span> -->

                        <div class="row mt-3 pl-3 pr-3">
                            <app-dropdown class="col-12 col-xl-4 pl-1 pr-1 mt-3" 
                                [list]="manufacturerList"
                                (selection)="manufacturerChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_Make' | translate">
                            </app-dropdown>
                            <app-dropdown class="col-12 col-xl-4 pl-1 pr-1 mt-3" 
                                [disabled]="!filter.manufacturerId"
                                [list]="selectedManufacturerModels() | async"
                                (selection)="modelChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_Model' | translate">
                            </app-dropdown>
                            <app-dropdown class="col-12 col-xl-4 pl-1 pr-1 mt-3"
                                [list]="mileageRanges"
                                (selection)="mileageChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_Mileage' | translate">
                            </app-dropdown>
                            <app-dual-dropdown class="col-xl-4 col-6 col-xs-12 pl-1 pr-1 mt-3"
                                [list]="yearRanges"
                                [minValue]="filter.yearFrom"
                                [maxValue]="filter.yearTo"
                                (minSelection)="minYearChange($event)"
                                (maxSelection)="maxYearChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_Year' | translate">
                            </app-dual-dropdown>

                            
                            <app-dual-dropdown class="col-xl-4 col-6 col-xs-12 pl-1 pr-1 mt-3"
                                [list]="priceRanges"
                                [minValue]="filter.priceFrom"
                                [maxValue]="filter.priceTo"
                                (minSelection)="minPriceChange($event)"
                                (maxSelection)="maxPriceChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_PriceTo' | translate">
                            </app-dual-dropdown>
                            
                            <app-dropdown class="col-xl-4 col-6 col-xs-12 pl-1 pr-1 mt-3"
                                [list]="globalService.carLots$ | async"
                                (selection)="carLotChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_CarLot' | translate">
                            </app-dropdown>
                            <app-dropdown class="col-xl-4 col-6 col-xs-12 pl-1 pr-1 mt-3"
                                [list]="globalService.gearboxTypes$ | async"
                                (selection)="transmissionChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_Transmission' | translate">
                            </app-dropdown>
                            <app-dropdown class="col-xl-4 col-6 col-xs-12 pl-1 pr-1 mt-3"
                                [list]="globalService.fuelTypes$ | async"
                                (selection)="fuleTypeChange($event)"
                                [placeholder]="'Component_Filter_Placeholder_FuelType' | translate">
                            </app-dropdown>
                        </div>

                        <!-- Desktop filter -->
                        <div class="row mt-4 d-none d-xl-flex">
                            <div class="col-5 d-flex">
                                <a class="accent m-auto" routerLink="/search">{{ 'Component_Filter_DetailedSearch' | translate }} ></a>
                            </div>
                            <div class="col-7">
                                <app-button [icon]="faSearch" (click)="searchClicked()">
                                    {{ 'Component_Filter_Search' | translate }}
                                </app-button>
                            </div>
                        </div>

                        <!-- Mobile filter -->
                        <div class="row mt-4 d-block d-xl-none">
                            <div class="col-12">
                                <app-button [icon]="faSearch" (click)="searchClicked()">
                                    {{ 'Component_Filter_Search' | translate }}
                                </app-button>
                            </div>
                            <div class="col-12 d-flex mt-4">
                                <a class="accent m-auto" routerLink="/search" fragment="filter">{{ 'Component_Filter_DetailedSearch' | translate }} ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>