import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition as RegularIconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input('icon')
  icon?: IconDefinition | RegularIconDefinition = undefined;

  @Output('click')
  click = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onButtonClicked() {
    this.click.emit();
  }
}
