import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[responsiveImage]'
})
export class ResponsiveImageDirective implements OnInit {

    @Input()
    referenceElement: HTMLElement = undefined;
    @Input("responsiveImage")
    basePath: string = undefined;

    sizeSuffixess = [{
            from: 0,
            to: 400,
            suffix: "-400"
        },
        {
            from: 400,
            to: 700,
            suffix: "-600"
        },
        {
            from: 700,
            to: 950,
            suffix: "-800"
        },
        {
            from: 950,
            to: Number.MAX_SAFE_INTEGER,
            suffix: "-1400"
        }
    ]

    constructor(private el: ElementRef) { }

    ngOnInit(): void {
        this.selectSize();
    }

    @HostListener('window:resize')
    onResize() {
        this.selectSize();
    }

    private selectSize() {
        let _this = this;
        let suffix = this.sizeSuffixess.filter(function(s) {
            return _this.referenceElement.clientWidth > s.from && _this.referenceElement.clientWidth <= s.to
        })[0];

        this.el.nativeElement.src = this.transformImagePath(this.basePath, suffix.suffix);
    }

    private transformImagePath(path: string, suffix: string) {
        return `${path}${suffix}.jpg`
    }
}
