<div class="card">
    <div class="card-body custom__rows_with_margin">
        <h5 class="m-2 card-title">{{ 'Page_Details_CarLotTitle' | translate | uppercase }}</h5>

        <div class="row">
            <div class="col-12 small font-weight-bold pb-2">{{ 'Page_Details_FindAt' | translate }}</div>
            <div class="col-12 small">{{vehicle.carLotAddress}} (<a
                [routerLink]="'/about'" [fragment]="'contacts'">{{ 'Page_Details_Map' | translate }}</a>) </div>
        </div>
        <hr class="mt-2 mb-2 d-none d-xl-block" />
        <div class="row" *ngFor="let m of vehicle.managers">
            <div class="col-12 small font-weight-bold">{{ 'Page_Details_Contacts' | translate }}</div>

            <div class="item__title col-4 col-sm-4 small text-left pr-1">{{ 'Page_Details_Manager' | translate }}</div>
            <div class="col-8 col-sm-8 small font-weight-bolder">{{m.name}}</div>

            <div class="item__title col-4 col-sm-4 small text-left pr-1">{{ 'Page_Details_PhoneNo' | translate }}</div>
            <div class="col-8 col-sm-8 small font-weight-bolder">{{m.phoneNo}}</div>

            <div class="item__title col-4 col-sm-4 small text-left pr-1">{{ 'Page_Details_Email' | translate }}</div>
            <div class="col-8 col-sm-8 small font-weight-bolder">{{m.email}}</div>
        </div>
    </div>
</div>