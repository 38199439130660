import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Scrollbar, Pagination } from 'swiper';


SwiperCore.use([Scrollbar, Pagination]);

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  @ViewChild('categorySlider') categorySlider!: SwiperComponent;

  pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    }
  };

  categories = [1,2,3,4,5,6,7]

  slidesBreakpoints = {
    0: {
      slidesPerView: 1
    },
    380: {
      slidesPerView: 2
    },
    680: {
      slidesPerView: 3
    },
    1260: {
      slidesPerView: 4
    },
    1580: {
      slidesPerView: 5
    },
  }

  constructor() { }

  ngOnInit(): void {
  }

  onProgress(e: any) {
  }
}
