<div class="d-inline-block w-100 position-relative" (clickOutside)="closeDropdown($event)">
    <button class="dropdown-button"
        [ngClass]="{
            'dropdown-button-active': dropdownOpen,
            'dropdown-button-disabled': disabled
        }"
        (click)="openDropdown($event)"
        [disabled]="disabled">

        <div class="d-flex justify-content-between">
            <div class="w-50 d-flex">
                <div *ngIf="minId">
                    <fa-icon tabindex="0" [icon]="faTimesCircle" (click)="clearMin()"></fa-icon>
                    {{minItem.name | translate }}
                </div>
                <div *ngIf="!minId">{{placeholder ? placeholder+' ': ''}}{{'Component_SidebarFilter_From' | translate }}</div>
            </div>

            <div class="min-max-separator">
                
            </div>

            <div class="w-50 d-flex justify-content-between">
                <div *ngIf="maxId">
                    <fa-icon tabindex="0" [icon]="faTimesCircle" (click)="clearMax()"></fa-icon>
                    {{maxItem.name | translate }}
                </div>
                <div *ngIf="!maxId">{{placeholder ? placeholder+' ': ''}}{{'Component_SidebarFilter_To' | translate }}</div>
                
                <fa-icon *ngIf="!dropdownOpen" [icon]="faChevronDown" (click)="openDropdown($event)"></fa-icon>
                <fa-icon *ngIf="dropdownOpen" [icon]="faChevronUp" (click)="closeDropdown($event)"></fa-icon>
            </div>
        </div>
        <!-- <div class="w-100 d-flex justify-content-between">
            <div *ngIf="selectedItem"> <fa-icon [icon]="faTimesCircle" (click)="clearClicked($event)"></fa-icon> {{selectedItem.name | translate }}</div>
            <div *ngIf="!selectedItem">{{placeholder | translate }}</div>

            <fa-icon *ngIf="!dropdownOpen" [icon]="faChevronDown" (click)="openDropdown($event)"></fa-icon>
            <fa-icon *ngIf="dropdownOpen" [icon]="faChevronUp" (click)="closeDropdown($event)"></fa-icon>
        </div> -->
    </button>

    
    <div #dropdownList class="dropdown-list w-100" aria-labelledby="dropdownManualMin" [ngClass]="{'dropdown-open' : dropdownOpen}">
        <div class="d-flex">
            
            <ul class="flex-grow-1 dropdown-list-part" role="listbox" aria-expanded="false">
                <li *ngFor="let o of list"
                    class="dual-dropdown-item"
                    [ngClass]="{'selected' : selectedItem === o, 'active': minId == o.id}"
                    tabindex="0"
                    (click)="minItemSelected(o.id, $event)"
                    role="option">{{o.name | translate}}</li>
            </ul>
            <ul class="flex-grow-1 dropdown-list-part" role="listbox" aria-expanded="false">
                <li *ngFor="let o of list"
                    class="dual-dropdown-item"
                    [ngClass]="{'selected' : selectedItem === o, 'active': maxId == o.id}"
                    tabindex="0"
                    (click)="maxItemSelected(o.id, $event)"
                    role="option">{{o.name | translate}}</li>
            </ul>
            <!-- <div class="flex-grow-1">
                <button class="w-100 dual-dropdown-item" [ngClass]="{'active': minId == item.id}" *ngFor="let item of list" (click)="minItemSelected(item.id, $event)"> {{ item.name }}</button>
            </div>
            <div class="flex-grow-1">
                <button class="w-100 dual-dropdown-item" [ngClass]="{'active': maxId == item.id}" *ngFor="let item of list" (click)="maxItemSelected(item.id, $event)"> {{ item.name }}</button>
            </div> -->
        </div>
    </div>

    <!-- <ul #dropdownList class="dropdown-list" role="listbox" aria-expanded="false" [ngClass]="{'dropdown-open' : dropdownOpen}">
        <li *ngFor="let o of list"
            [ngClass]="{'selected' : selectedItem === o}"
            tabindex="0"
            (click)="select(o.id)"
            role="option">{{o.name | translate}}</li>
    </ul> -->
</div>