import { Pipe, PipeTransform } from '@angular/core';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';

@Pipe({name: 'idToName'})
export class IdToNamePipe implements PipeTransform {
  transform(value: number|null, valueList: SelectionItem[]|null): string {

    if(!value || !valueList)
    {
      return `${value}`;
    }

    let values = valueList.filter(v => v.id == value);
    if (values.length < 1) {
        return `${value}`;
    }
    
    return values[0].name;
  }
}