import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { VehicleListModel } from 'src/app/model/vehicle/vehicle-list.dto';
import { VehicleListFilter } from 'src/app/model/vehicle/vehicle-list.filter';
import { VehicleService } from 'src/app/services/vehicle.service';
import { faSlidersH, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  faSlidersH = faSlidersH;
  faTimes = faTimes;

  perPage: number = 20;

  vehicles: VehicleListModel[] = [];
  totalCount: number = 0;
  filter = new VehicleListFilter();
  filterShow: boolean = false;

  scrollTop = 0;
  hideNav = false;

  successfulyLoaded: boolean = false;

  constructor(
    private meta: Meta,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.meta.addTags([
      {name: 'description', content: 'Autopark parduoda aukštos kokybės naudotus automobilius ir suteikia garantiją!'},
      {name: 'keywords', content: 'autopark,parduoda,aukštos,kokybės,naudotus,automobilius,suteikia,garantiją,daugiau,apie,paslaugas'}
    ]); 

    this.route.queryParamMap.subscribe(p => {
      if (p.keys.length > 0) this.filter.take = this.perPage;
      this.filter = new VehicleListFilter(this.route.snapshot.queryParams);
      this.load();
    })
    this.route.fragment.subscribe(f => {
      if (f == 'filter') {
        this.showFilter();
      }
    })
  }

  ngOnInit(): void {
    this.filter.take = this.perPage;
    this.filter = new VehicleListFilter(this.route.snapshot.queryParams);
    
    this.load();
  }

  onFilterChange(e: any) {
    this.load();
  }

  onSearchClicked() {
    this.load();
    this.filterShow = false;
  }

  onNextClicked() {
    this.router.navigate(['/'], {skipLocationChange: true});
    this.router.navigate(['/search'], {
      queryParams: this.changePage(this.route.snapshot.queryParams, this.filter.page + 1)
    });
  }

  onPrevClicked() {
    this.router.navigate(['/'], {skipLocationChange: true});
    this.router.navigate(['/search'], {
      queryParams: this.changePage(this.route.snapshot.queryParams, this.filter.page - 1)
    });
  }

  private changePage(params: Params, newPage: number): Params {
    let newParams = Object.assign({}, params);
    newParams.page = newPage;
    return newParams;
  }

  load() {
    this.vehicleService.list(this.filter)
    .subscribe(v => {
      this.vehicles = v.list;
      this.totalCount = v.total;
      this.successfulyLoaded = true;
    })
  }

  showFilter() {
    this.filterShow = true;
  }

  close() {
    this.filterShow = false;
  }

  
  
  @HostListener('document:scroll', ['$event'])
  onScroll(event) {
    this.hideNav = this.scrollTop < event.target.scrollingElement.scrollTop;
    this.scrollTop = event.target.scrollingElement.scrollTop;
  }
}
