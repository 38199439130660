<div class="m-4">
    <div class="static-header">
        <h2 class="font-weight-bold">Apie mus</h2>
    </div>
    <div class="static-body mt-4 text-justify">
        <p>Vilniuje, Antakalnio mikrorajone, įsikūręs automobilių prekybos centras autopark. Automobilių prekybos centre vienu metu telpa iki 100 automobilių. Po atviru dangumi įsikūrusiame centre galima įsigyti pačių įvairiausių automobilių: pradedant prabangiais, sportiniais, dideliais ir baigiant tradiciniais, mažais ir labai patraukliais.</p>
        <p>Svarbiausias įvertinimas mums - teigiamos mūsų klientų rekomendacijos. Todėl ypatingą dėmesį skiriame parduodamų automobilių kokybei, saugumui ir techninio tvarkingumo įvertinimui. Dauguma automobilių nauji buvo pirkti iš dilerio Lietuvoje ir visą amžių aptarnauti ir kvalifikuotai prižiūrėti autorizuotų gamintojų atstovų. Tokie, kurių Lietuvoje tiesiog neįmanoma nupirkti perkami tik iš laiko patikrintų partnerių Vokietijoje, kuriems automobilio kokybė, saugumas ir patikimumas - tokios pačios svarbios vertybės kaip ir mums.</p>
        <p>autopark - dešimtis metų trukusios patirties automobilių versle rezultatas. Automobilių prekybos centro AUTOPARK privalumas - išskirtinis dėmesys klientui. Mes nedirbame „konvejerio“ principu. Jums išsirinkus automobilį mūsų darbas tik prasideda - automobiliams yra suteikiama 100 dienų garantija, tvarkome dokumentus ir automobilio draudimą, padedame susitvarkyti finansavimą, konsultuojame techniniais, eksploataciniais ir mokestiniais klausimais.</p>
    </div>
    <div class="static-footer">
        <div class="d-flex flex-column pl-4 pr-4 pt-3 pb-3">

            <div class="d-flex justify-content-between">
                <span class="small">© 2010-2021 UAB Autopark</span>
                <span class="small">Privatumo politika</span>
            </div>
            
            <div class="d-flex justify-content-between">
                <span class="small">Visos teisės saugomos.</span>
                <span></span>
            </div>

            <div class="d-flex justify-content-between">
                <span class="small">Antakalnio 124a, Vilnius.</span>
                <span class="small">info@autopark.lt</span>
            </div>

            <div class="d-flex justify-content-between">
                <span class="small">Laisvės pr. 43A, Vilnius.</span>
                <span class="small">+370 612 20222</span>
            </div>
        </div>
    </div>
</div>