import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectorComponent } from './core/infrastructure/redirector/redirector.component';
import { AboutUsComponent } from './core/page/about-us/about-us.component';
import { DetailsComponent } from './core/page/details/details.component';
import { FaqComponent } from './core/page/faq/faq.component';
import { HomeComponent } from './core/page/home/home.component';
import { PrivacyPolicyComponent } from './core/page/privacy-policy/privacy-policy.component';
import { SearchComponent } from './core/page/search/search.component';
import { StaticComponent } from './core/page/static/static.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'detail/:car', component: DetailsComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'search', component: SearchComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'static', component: StaticComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
