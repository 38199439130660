import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { filter, forkJoin } from 'rxjs';
import { NotificationDialogComponent } from './core/component/notification-dialog/notification-dialog.component';
import { NotificationModel } from './model/global/notification-model';
import { GlobalService } from './services/global.service';
import { NotificationService } from './services/notification.service';

//export const BASE_ADDR = "http://70.34.219.28";
export const BASE_ADDR = "https://autopark.lt";
//export const BASE_ADDR = "http://localhost:4200";

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'AutoParkWebAngular';
  notification: NotificationModel;
  
  constructor(private translate: TranslateService,
    globalService: GlobalService,
    router: Router,
    viewportScroller: ViewportScroller,
    private notificationService: NotificationService,
    private modalService: SimpleModalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd && isPlatformBrowser(this.platformId)){
        ga('create', 'UA-13217258-1', 'auto');
        ga('send', 'pageview');
      }
    });

    translate.setDefaultLang('en');
    translate.use('lt');
    router.events.pipe(
      filter((e => {
        return e instanceof Scroll
      }))
    ).subscribe(evnt => {
      let e = <Scroll><any>evnt;
      if (e.position) {
        // backward navigation
        setTimeout(() => viewportScroller.scrollToPosition(e.position), 500);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });

    // Prefetch required values
    forkJoin([
      globalService.gearboxTypes$,
      globalService.fuelTypes$,
      globalService.colors$,
      globalService.bodyTypes$,
      globalService.doorsTypes$
    ]).toPromise().then(
    ([gearboxTypes, fuelTypes, colors, bodyTypes, doorsTypes]) => {});
  }

  ngAfterViewInit(): void {
    this.notificationService.getNotification()
    .subscribe(n => {

      if (n && localStorage.getItem(n.id+this.translate.currentLang) == undefined) {
        this.notification = n;      
        this.modalService.addModal(NotificationDialogComponent, {
          header: n.header,
          body: n.body,
          id: n.id
        })
        //this.content.openNotificationDialog();
      }
    })
  }

}
