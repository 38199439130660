import { Component, HostListener, OnInit } from '@angular/core';
import { faCheck, faCheckCircle, faRoad, faBolt, faGasPump, faCogs } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { VehicleService } from 'src/app/services/vehicle.service';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';
import { GlobalService } from 'src/app/services/global.service';
import SwiperCore, {Zoom, Navigation, Pagination} from 'swiper'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { SelectionItem } from 'src/app/model/shared/selection-item.dto';
import { Resolver, RouteAware } from '@bespunky/angular-zen/router-x';

SwiperCore.use([Navigation, Pagination]);

import { Injectable } from '@angular/core';
import { delay, flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BASE_ADDR } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalGalleryComponent } from '../../component/modal-gallery/modal-gallery.component';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent extends RouteAware implements OnInit {
  BASE_ADDR = BASE_ADDR;
  
  kwToHpRatio: number = 1.36;

  vehicleId?: number = undefined;

  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faRoad = faRoad;
  faBolt = faBolt;
  faGasPump = faGasPump;
  faCogs = faCogs;
  faCalendar = faCalendar;
  
  vehicle?: VehicleModel;
  showModal: boolean = false;

  isMobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XLarge, Breakpoints.Large])
    .pipe(
      map(x => !x.matches),
      shareReplay()
    );

  constructor(
    private meta: Meta,
    protected route: ActivatedRoute,
    private service: VehicleService,
    protected router: Router,
    public location: Location,
    public globalService: GlobalService,
    public breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private modalService: SimpleModalService
  ) {
    super(router, route);

    this.route.paramMap.pipe(
      map(p => {
        if (!p.has('car')) {
          this.router.navigate(['/']);
        }
        
        let vehicleId = parseInt(p.get('car')?.split('-')?.pop()!);
        if (vehicleId == undefined) {
          this.router.navigate(['/']);
        }
        return vehicleId;
      }),
      flatMap(vehicleId => {
        return forkJoin([
          this.service.single(vehicleId),
          of(vehicleId),
          this.globalService.gearboxTypes$,
          this.globalService.fuelTypes$,
          this.globalService.colors$,
          this.globalService.bodyTypes$,
          this.globalService.doorsTypes$,
          this.globalService.vehicleFeatures$
        ])
      }),
      map(([vehicle, vehicleId, gearboxTypes, fuelTypes, colors, bodyTypes, doorsTypes, features]) => {
        let v = vehicle;
        let metaTags = [
          {name: 'description', content: `${v.title}, `
            + `Metai: ${v.manufactureDate}, `
            + `Rida: ${Math.round(v.mileage)}, `
            + `Pavarų dėžė: ${this.translateId(v.transmissionType, gearboxTypes, true)}, `
            + `Kuras: ${this.translateId(v.fuelType, fuelTypes, true)}, `
            + `Variklio tūris: ${Math.round(v.engineVolume/100) / 10} l, `
            + `Galia: ${Math.round(v.power)} kW, `
            + `Spalva: ${this.translateId(v.colorId, colors, true)}, `
            + `Kėbulas: ${this.translateId(v.bodyTypeId, bodyTypes, true)}, `
            + `Durų skaičius: ${this.translateId(v.noOfDoorsId, doorsTypes, true)}`},
          {name: 'keywords', content: `${v.title},`
            + v.features.map(f => this.translateId(f, features, true)).join(",")}
        ];
        this.meta.addTags(metaTags); 

        return [vehicle, vehicleId];
      })
    ).subscribe(([v, vId]) => {
      this.vehicle = <VehicleModel>v;
      this.vehicleId = <number>vId;
    })

  }

  translateId(id: number, values: SelectionItem[], translate: Boolean = false) {
    if (!id) return '';

    let filtered = values.filter(v => v.id == id);
    if (filtered.length > 0)
      return translate ? this.translate.instant(filtered[0].name) : filtered[0].name;
    return '';
  }

  ngOnInit(): void {
  }
  
  openModal() {
    this.modalService.addModal(ModalGalleryComponent, {
      images: this.vehicle.images
    })
  }
}
