import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FaqGroupModel } from 'src/app/model/static/faq-group.model';
import { FaqItemModel } from 'src/app/model/static/faq-item.model';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  groups: FaqGroupModel[] = [];

  constructor(
    private meta: Meta,
    private service: FaqService,
    public route: ActivatedRoute,
  ) {
    route.fragment.subscribe(f => {
      if (f == "loan-residual-payment") {

      }
    })
    
    this.meta.addTags([
      {name: 'description', content: 'Autopark parduoda aukštos kokybės naudotus automobilius ir suteikia garantiją!'},
      {name: 'keywords', content: 'autopark,parduoda,aukštos,kokybės,naudotus,automobilius,suteikia,garantiją,daugiau,apie,paslaugas'}
    ]); 
  }

  ngOnInit(): void {
    this.service.list()
    .subscribe(g => this.groups = g);
  }

}
