<div class="header-language border-left d-none d-xl-block">
    <div class="d-inline-block lang-selection h-100 pl-3 pr-3">
        <button class="dropdown-button"
            (click)="toggleDropdown($event)">

            <div class="w-100 d-flex justify-content-between">
                <span class="flag-icon flag-icon-squared rounded-circle" [ngClass]="selectedLangIcon"></span>

                <fa-icon class="pl-2" *ngIf="!dropdownOpen" [icon]="faChevronDown" (click)="openDropdown($event)"></fa-icon>
                <fa-icon class="pl-2" *ngIf="dropdownOpen" [icon]="faChevronUp" (click)="closeDropdown($event)"></fa-icon>
            </div>
        </button>
        <ul #dropdownList class="dropdown-list" role="listbox" aria-expanded="false" [ngClass]="{'dropdown-open' : dropdownOpen}">
            <li (click)="selectLang('lt')" class="language-selection-item w-100 d-flex"><button class="m-auto"><span class="flag-icon flag-icon-lt flag-icon-squared rounded-circle mr-1"></span></button></li>
            <li (click)="selectLang('en')" class="language-selection-item w-100 d-flex"><button class="m-auto"><span class="flag-icon flag-icon-gb flag-icon-squared rounded-circle mr-1"></span></button></li>
            <!-- <div class="language-selection-item w-100 d-flex"><button class="m-auto"><span class="flag-icon flag-icon-ru flag-icon-squared rounded-circle mr-1"></span></button></div> -->
        </ul>
    </div>
</div>