import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResult } from '../model/shared/api-list-result.dto';
import { BaseFilter } from '../model/shared/base.filter';
import { AsyncApiCallHelperService, HttpService } from './http.service';
import { VehicleListModel } from '../model/vehicle/vehicle-list.dto'
import { VehicleModel } from '../model/vehicle/vehicle.dto';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends HttpService {
  private path: string = "/api/vehicle/";

  constructor(protected http: HttpClient, helper: AsyncApiCallHelperService) {
    super(http, helper);
  }
  
  list(filter: BaseFilter): Observable<ApiListResult<VehicleListModel>> {
    let query = filter?.getFilter();
    let url = `${this.path}list${query ? "?"+query : ""}`;

    return this.getCached<ApiListResult<VehicleListModel>>(url);
  }

  single(id: number): Observable<VehicleModel> {
    return this.get<VehicleModel>(this.path+`${id}`);
  }
}
