import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';



export type TranslateFunction = (value: number) => string;

@Component({
  selector: 'app-fat-slider',
  templateUrl: './fat-slider.component.html',
  styleUrls: ['./fat-slider.component.css']
})
export class FatSliderComponent implements OnInit {

  @ViewChild('rangeSlider') slider: ElementRef;
  @ViewChild('labelBefore') labelBefore: ElementRef;
  labelInSelected: boolean = true;
  formattedValue: string = "";

  @Input()
  value: number = 48;
  @Output()
  valueChange = new EventEmitter<number>();
  @Input()
  min = 36;
  @Input()
  max = 72;
  @Input()
  step = undefined;
  @Input()
  translate: TranslateFunction = v => `${v}`;

  constructor() { }

  ngOnInit(): void {
    this.formattedValue = this.getDisplayValue(this.value);
    this.recalcLabelPosition();
  }

  valueChanged() {
    this.formattedValue = this.getDisplayValue(this.value);
    this.recalcLabelPosition();

    this.valueChange.emit(this.value);
  }

  recalcLabelPosition() {
    let newPos = this.getThumbPos();

    let labelSpace = newPos;
    let notLabelSpace = this.slider.nativeElement.clientWidth - labelSpace;
    
    if (!this.labelInSelected && labelSpace > notLabelSpace) {
      this.labelInSelected = true;
    } else if (this.labelInSelected && labelSpace < notLabelSpace) {
      this.labelInSelected = false;
    }
  }

  getThumbPos(): number {
    if (!this.slider || !this.slider.nativeElement)
      return 1;
      
    let widht = this.slider.nativeElement.clientWidth;
    
    return (this.value - this.min) / (this.max - this.min) * widht;
  }

  private getDisplayValue(value: number): string {
    return this.translate(value);
  }
}
