<div class="position-absolute w-100 h-50 background-primary slanted-background-up"></div>
<div class="position-absolute w-100 h-50 background-primary slanted-background-down"></div>

<div class="container-fluid background-primary slanted-content">
    <div class="row pt-5 pb-5">
        <div class="d-none d-xl-flex col-2 offset-2 justify-content-end">
            <!-- <span class="category-title font-weight-bold mt-auto mb-auto">{{ 'Component_Categories_PopularAt' | translate }} Auto<span class="accent">Park</span></span> -->
            <img class="w-75" src="assets/img/autopark-logo.svg"/>
        </div>
        <div class="col-12 col-xl-7">

            <swiper #categorySlider class="swiper-container gallery-top"
                [scrollbar]="{draggable: true}"
                [loop]="true"
                (progress)="onProgress($event)"
                [breakpoints]="slidesBreakpoints"
                direction="horizontal">

                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleStateId: 2, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1" [routerLink]="['/search']" [queryParams]="{vehicleStateId: 2, page: 1}">
                            <p class="font-weight-bold text-center">{{ 'Component_Categories_Incoming' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/almost-new.png"/>
                    </div>
                </ng-template>

                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{bodyType: 2, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1" [routerLink]="['/search']" [queryParams]="{bodyType: 2, page: 1}">
                            <p class="font-weight-bold text-center">{{ 'Component_Categories_SUV' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/truck.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2259, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Family' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/family.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2137, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Luxury' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/luxury.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2139, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Sports' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/sport.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2264, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_FuelEfficient' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/fabia.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2261, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Carrieres' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/caddy.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2262, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Minivans' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/minibus.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2258, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_MidSize' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/octavia.png"/>
                    </div>
                </ng-template>
                
                <ng-template swiperSlide>
                    <div class="car-category d-flex flex-column" [routerLink]="['/search']" [queryParams]="{vehicleType: 2265, page: 1}">
                        <div class="w-100 car-category-title flex-grow-1">
                            <p class="font-weight-bold text-center">{{ 'Classifier_Ecological' | translate }}</p>
                        </div>
                        <img class="car-category-image" src="/assets/img/vwup.png"/>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</div>