import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaqItemModel } from 'src/app/model/static/faq-item.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {
  faPlus = faPlus;
  faMinus = faMinus;

  @Input()
  items: FaqItemModel[] = [];
  @Input()
  groupId: number = 0;
  @Input('openedFragment')
  set openedFragment(val: string) {
    if (val == 'loan-residual-payment') {
      this.initialOpen = "FaqItem_3_Title";
    }
  }
  
  initialOpen: string = undefined;

  constructor() { }

  ngOnInit(): void {
  }
  
  collapsing = true;
}
