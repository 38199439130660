import { Component, OnInit, Renderer2 } from '@angular/core';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  faBars = faBars;
  faChevronDown = faChevronDown;
  showMenu: boolean = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }
  
  showMenuClicked() {
    this.showMenu = true;
    this.onShowChange();
  }

  onShowChange() {
    // if (this.showMenu) {
    //   this.renderer.addClass(document.body, 'noscroll');
    // } else {
    //   this.renderer.removeClass(document.body, 'noscroll');
    // }
  }



  private swipeCoord?: [number, number];
  private swipeTime?: number;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 100 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        
        if (direction[0] > 0) {
          this.showMenu = false;
        }
      }
    }
  }
}
