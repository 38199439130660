<div class="container">

    <div class="content">
        <div class="block-01 posrel">

            <div class="cor-01">&nbsp;</div>
            <div class="cor-02">&nbsp;</div>
            <div class="cor-03">&nbsp;</div>
            <div class="cor-04">&nbsp;</div>
            <div class="top">
                <h1 class="block fl display-5 font-weight-bold accent mb-4">Privatumo politika</h1>
            </div>
            <div class="text-inside">
                <div class="wf">
                    <div class="document">
                        <h2 class="font-weight-bold mb-4">INTERNETO SVETAINĖS WWW.AUTOPARK.LT PRIVATUMO POLITIKA</h2>
                        <h3><strong>Bendrosios nuostatos</strong></h3>
                        <p>Ši interneto svetainės <a href="http://www.autopark.lt/">www.autopark.lt</a>
                            (toliau – Svetainė) privatumo politika (toliau – Privatumo politika)
                            nustato ir užtikrina privatumo sąlygų, Jums naudojantis Svetaine,
                            įgyvendinimą. Joje atskleidžiama, kokiais principais vadovaujamės ir
                            kaip elgiamės, tvarkydami Jūsų asmens duomenis, kaip mes juos gauname.</p>
                        <p>Privatumo politika taikoma visiems asmenims, apsilankiusiems Svetainėje,
                            nepaisant to, ar jie yra joje užsiregistravęs ar ne.</p>
                        <p>Šioje Privatumo politikoje naudojamos sąvokos atitinka Bendrajame duomenų
                            apsaugos reglamente vartojamas sąvokas:</p>
                        <p>asmens duomenys – bet kokia informacija apie fizinį asmenį, kurio
                            tapatybė nustatyta arba kurio tapatybę galima nustatyti (duomenų
                            subjektas); fizinis asmuo, kurio tapatybę galima nustatyti, yra asmuo,
                            kurio tapatybę tiesiogiai arba netiesiogiai galima nustatyti, visų pirma
                            pagal identifikatorių, kaip antai vardą ir pavardę, asmens
                            identifikavimo numerį, buvimo vietos duomenis ir interneto
                            identifikatorių arba pagal vieną ar kelis to fizinio asmens fizinės,
                            fiziologinės, genetinės, psichinės, ekonominės, kultūrinės ar socialinės
                            tapatybės požymius;</p>
                        <p>duomenų tvarkymas – bet kokia automatizuotomis arba
                            neautomatizuotomis priemonėmis su asmens duomenimis ar asmens duomenų
                            rinkiniais atliekama operacija ar operacijų seka, kaip antai rinkimas,
                            įrašymas, rūšiavimas, sisteminimas, saugojimas, adaptavimas ar keitimas,
                            išgava, susipažinimas, naudojimas, atskleidimas persiunčiant, platinant
                            ar kitu būdu sudarant galimybę jais naudotis, taip pat sugretinimas ar
                            sujungimas su kitais duomenimis, apribojimas, ištrynimas arba
                            sunaikinimas;</p>
                        <p>duomenų valdytojas – fizinis arba juridinis asmuo, valdžios
                            institucija, agentūra ar kita įstaiga, kuri vienas ar kartu su kitais
                            nustato duomenų tvarkymo tikslus ir priemones; kai tokio duomenų
                            tvarkymo tikslai ir priemonės nustatyti Europos Sąjungos arba valstybės
                            narės teisės, duomenų valdytojas arba konkretūs jo skyrimo kriterijai
                            gali būti nustatyti Europos Sąjungos arba valstybės narės teise;</p>
                        <p>duomenų tvarkytojas – fizinis arba juridinis asmuo, valdžios
                            institucija, agentūra ar kita įstaiga, kuri duomenų valdytojo vardu
                            tvarko asmens duomenis;</p>
                        <p>slapukai (cookies) – itin nedidelės apimties tekstiniai failai,
                            laikinai įrašomi į lankytojo įrenginio, kuriuo naršoma Svetainėje,
                            standųjį diską. Jie leidžia atpažinti lankytoją per kitus apsilankymus
                            Svetainėje, išsaugoti jo naršymo istoriją, parinktis.</p>
                        <p>Užtikriname, kad Jūsų asmens duomenys tvarkomi teisėtai, skaidriai ir
                            sąžiningai, iš anksto nustatytais tikslais ir tik tokia apimtimi, kiek
                            tai reikalinga atitinkamiems tikslams pasiekti. Siekiame, kad Jūsų
                            asmens duomenys būtų tikslūs, saugūs, konfidencialūs, tinkamai laikomi
                            ir saugomi. Tai atlikdami, vadovaujamės Bendrojo duomenų apsaugos
                            reglamente (toliau – Reglamentas) ir kituose teisės aktuose nustatytais
                            asmens duomenų tvarkymo reikalavimais.</p>
                        <p>Privatumo politikos pakeitimai ir tikslinimai bus skelbiami
                            Svetainėje, nurodant pakeitimų ir tikslinimų esmę, kad Svetainės
                            lankytojai turėtų galimybę su jais susipažinti.</p>
                        <h3><strong>Asmens duomenų tvarkymo principai</strong></h3>
                        <p>Svetainę www.autopark.lt tvarko ir administruoja UAB Autopark,
                            juridinio asmens kodas 125691822, adresas Antakalnio 124a, 10200 Vilnius
                            (toliau – Įmonė).</p>
                        <p>Vadovaujantis teisės aktų nuostatomis, Įmonė laikoma Jūsų asmens
                            duomenų valdytoju. Su Įmone dėl Privatumo politikos galima susisiekti
                            el. paštu: info@autopark.lt arba telefonu +370 612 20222.</p>
                        <p style="padding: 0px;">Tvarkydami asmens duomenis,</p>
                        <ul>
                            <li>laikysimės galiojančių teisės aktų reikalavimų;</li>
                            <li>juos tvarkysime teisėtai, sąžiningai ir skaidriai, aiškiai apibrėžtais bei
                                teisėtais tikslais;</li>
                            <li>sieksime, kad netikslūs asmens duomenys, esant reikalui, būtų tikslinami,
                                pildomi, o numatytais atvejais – ir naikinami;</li>
                            <li>saugosime tiek ir tokia forma, kad subjekto tapatybę būtų galima
                                nustatyti ne ilgiau, nei tai yra būtina siekiant tų tikslų, kuriems
                                duomenys yra renkami, tvarkomi ir saugomi;</li>
                            <li>neteiksime asmens duomenų tretiesiems asmenims ir jų neviešinsime,
                                nebent teisės aktų bei šios Privatumo politikos nustatytais atvejais.</li>
                        </ul>
                        <h3><strong>Tvarkomi asmens duomenys ir jų tvarkymo tikslai</strong></h3>
                        <p>Asmens duomenys tvarkomi vadovaujantis vartotojo pateiktu sutikimu,
                            atsižvelgiant į teisės aktų bei šios Politikos nustatytus reikalavimus.
                            Asmens duomenys taip pat tvarkomi siekiant įgyvendinti teisės aktais
                            svetainės valdytojui nustatytas prievoles.</p>
                        <p>Tvarkomi asmens duomenys bei jų pobūdis priklauso nuo to, ar asmuo
                            Svetainėje yra užsiregistravęs ar joje naršo kaip neregistruotas
                            vartotojas.</p>
                        <p>Jeigu asmuo yra registruotas Svetainėje (arba siunčia užklausas, arba užsisako
                            Naujienlaiškį), tvarkomi šie duomenys:</p>
                        <p>paties duomenų subjekto pateikti identifikaciniai duomenys (pvz., vardas,
                            pavardė, el. pašto adresas ar tel. numeris ir pan.);</p>
                        <p>paties duomenų subjekto pateikti kontaktiniai duomenys (pvz., el.
                            pašto adresas, telefono numeris, socialinio tinklo paskyra ar pan.).</p>
                        <p>Jeigu asmuo yra neregistruotas Svetainėje, tvarkomi šie duomenys:</p>
                        <p>interneto adresas (IP), naudojamos internetinės naršyklės tipas,
                            apsilankymų skaičius, peržiūrėti Svetainės puslapiai, laikas, praleistas
                            Svetainėje, paspaustos nuorodos, reklamos ir kt. Taip siekiama
                            tobulinti Svetainės turinį, patrauklumą vartotojams ir reklamos
                            užsakovams.</p>
                        <p>Asmens duomenys tvarkomi siekiant užtikrinti Svetainės patrauklumą,
                            funkcionalumą, priimant sprendimus dėl jos turinio bei programinės
                            dalies tobulinimo, taip pat siekiant sudaryti vartotojams lengvesnes
                            Svetainės naršymo sąlygas, ją derinant prie vartotojų poreikių, siekiant
                            vartotojams pateikti individualizuotus reklamos pasiūlymus ir norint
                            įvertinti Svetainės lankomumą, jos veikimą.</p>
                        <p>Asmens duomenys renkami ir tvarkomi siekiant registruotiems
                            vartotojams pateikti naujienas, pranešimus apie Svetainės turinio
                            atnaujinimus, naujus pasiūlymus.</p>
                        <h3><strong>Slapukų (cookies) naudojimas</strong></h3>
                        <p><strong>S</strong>vetainėje duomenys apie jos lankytojus renkami
                            naudojant slapukus (angl. cookies). Tai itin nedidelės apimties failai,
                            laikinai įrašomi į lankytojo įrenginio, kuriuo naršoma Svetainėje,
                            standųjį diską. Slapukai leidžia atpažinti lankytoją, kai jis kitą
                            kartą apsilanko Svetainėje, taip pat leidžia išsaugoti jo naršymo
                            istoriją, parinktis, todėl lankytojui pateikiamas jam labiau
                            pritaikytas turinį, pagreitinama Svetainėje vykdoma paieška. Pabrėžtina,
                            kad Slapukai naudojami didžiojoje daugumoje internetinių svetainių.</p>
                        <p>Padedant Slapukams, gaunama informacija naudojama siekiant
                            užtikrinti, kad Svetaine būtų naudojamasi patogiau (pvz., ją
                            „personalizuojant“ pagal lankytojo poreikius), jie padeda tobulinti
                            Svetainę, analizuoti lankytojų įpročius, veiksmingiau planuoti
                            rinkodarines kampanijas.</p>
                        <p style="padding: 0px;"><strong>Svetainėje gali būti naudojami tokie
                                slapukai:</strong></p>
                        <ul>
                            <li>būtinieji techniniai slapukai – tai slapukai, kurie yra būtini Svetainės
                                veikimui;</li>
                            <li>funkciniai slapukai – tai slapukai, kurie, nors ir nebūtini
                                Svetainės veikimui, tačiau žymiai pagerina jos veikimą, kokybę ir
                                vartotojų patirtį;</li>
                            <li>statistiniai slapukai – tai slapukai, kurie naudojami Svetainės
                                lankytojų navigacijos metodų statistinei analizei parengti. Šių slapukų
                                surinkti duomenys naudojami anonimiškai;</li>
                            <li>tiksliniai arba reklaminiai slapukai – tai slapukai, kurie naudojami
                                siekiant parodyti pasiūlymus ar kitą informaciją, kuri galėtų Jus
                                sudominti.</li>
                        </ul>
                        <h3><strong>Svetainėje naudojami slapukai</strong></h3>
                        <h4><strong>Būtinieji techniniai</strong></h4>
                        <table cellpadding="3">
                            <tbody>
                                <tr>
                                    <td style="width: 84px;">
                                        <p><strong>Pavadinimas</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Galiojimas</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Aprašymas</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 84px;">
                                        <p>sessionId</p>
                                    </td>
                                    <td>
                                        <p>Iki naršyklės uždarymo</p>
                                    </td>
                                    <td>
                                        <p>Reikalingas tam, kad naviguojant tarp puslapių, sistema suprastų,
                                            jog
                                            tai to paties žmogaus naršymas, o ne atskirų žmonių skirtingų
                                            puslapių
                                            atvertimai.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>cookieconsent_status</p>
                                    </td>
                                    <td>
                                        <p>1 metai nuo paskutinio apsilankymo</p>
                                    </td>
                                    <td>
                                        <p>Slapukas saugo žymę, kad jūs pasirinkome uždaryti pranešimą apie
                                            slapukų naudojimą.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p><strong> </strong></p>
                        <h4><strong>Statistiniai</strong></h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td style="width: 84px;">
                                        <p><strong>Pavadinimas</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Galiojimas</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Aprašymas</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>_ga</p>
                                    </td>
                                    <td>
                                        <p>2 metai nuo paskutinio apsilankymo</p>
                                    </td>
                                    <td>
                                        <p>Naudojamas Google Analytics svetainės vartotojų veiksmų
                                            monitoringui.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>_gid</p>
                                    </td>
                                    <td>
                                        <p>24 val. nuo paskutinio apsilankymo</p>
                                    </td>
                                    <td>
                                        <p>Naudojamas Google Analytics svetainės vartotojų veiksmų
                                            monitoringui.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>_gat</p>
                                    </td>
                                    <td>
                                        <p>1 min. &nbsp;nuo paskutinio apsilankymo</p>
                                    </td>
                                    <td>
                                        <p>Naudojamas Google Analytics svetainės vartotojų veiksmų
                                            monitoringui.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3><strong>Asmens duomenų saugojimo tvarka</strong></h3>
                        <p>Duomenys apie Svetainės lankytojus, kurie mus pasiekia, kaupiami ir
                            saugomi ES teritorijoje, tačiau, esant reikalui, jie gali būti perduoti
                            saugoti ir už ES ribų, užtikrinant, kad jie būtų tvarkomi saugiai ir
                            laikantis teisės aktų bei Privatumo politikos nuostatų.</p>
                        <p>Duomenys saugomi ne ilgiau, negu to reikalauja duomenų tvarkymo
                            tikslai ar numato teisės aktai, jeigu juose yra nustatytas ilgesnis
                            duomenų saugojimo terminas. Pavyzdžiui, užsiregistravusių Svetainėje
                            lankytojų duomenys saugomi ne trumpiau, nei asmuo yra užsiregistravęs
                            Svetainėje. Be to, saugomi tik atnaujinti duomenys – pasenę (buvę iki
                            atnaujinimo) duomenys yra naikinami, jie nesaugomi, nebent tokį
                            reikalavimą nustato teisės aktai.</p>
                        <p>Svetainėje surinkti duomenys nebus teikiami tretiesiems asmenims,
                            išskyrus Privatumo politikoje numatytais atvejais, esant teisėtam
                            pagrindui perduoti juos tretiesiems asmenims. Be to, jie bus naudojami
                            tik Privatumo politikoje numatytais tikslais ir būdais arba įgyvendinant
                            atitinkamas teisės aktų nuostatas.</p>
                        <p>Tretiesiems asmenims Svetainėje surinkti duomenys gali būti
                            atskleisti tik paslaugų teikėjams (pvz., įgyvendinant sutartis su jais
                            paslaugų teikėjais), tačiau pastariesiems keliamas reikalavimas
                            užtikrinti, jog gauti duomenys būtų naudojami tik Svetainei ar duomenų
                            valdytojui paslaugų teikimo tikslais.</p>
                        <p>Įvykus bet kokiam asmens duomenų saugumo pažeidimui, kuris taptų mums
                            žinomas ir galėtų daryti įtaką Svetainės lankytojų teisėms, Svetainė
                            įsipareigoja, nustačius, kokių asmenų ir kokie duomenys buvo pažeisti,
                            nedelsiant pranešti apie jų duomenų saugumo pažeidimą.</p>
                        <h3><strong>Asmens duomenų subjekto teisės</strong></h3>
                        <p>Svetainės lankytojai, kaip asmens duomenų subjektas, turi teisės
                            aktais įtvirtintas teises: būti informuoti apie savo asmens duomenų
                            tvarkymą ir susipažinti su tvarkomais savo asmens duomenimis; nustačius,
                            kad asmens duomenys yra netikslūs, reikalauti juos ištaisyti ar
                            papildyti ar reikalauti sunaikinti duomenis (kai jie tampa nereikalingi
                            tikslams, kuriems buvo surinkti ar tvarkomi neteisėtai). Be to, asmens
                            duomenų subjektas turi teisę atšaukti anksčiau duotą sutikimą tvarkyti
                            asmens duomenis, pareikalauti, kad surinkti asmens duomenys lengvai
                            skaitomu formatu būtų pateikti jam pačiam arba persiųsti kitam duomenų
                            valdytojui (su sąlyga, jeigu tai įmanoma techniškai įgyvendinti).</p>
                        <p>Prašymas teikiamas raštu šios Privatumo politikos skyriuje „Asmens
                            duomenų tvarkymo principai“ nurodytu el. paštu ar siunčiant paklausimą
                            (pranešimą) įmonės buveinės adresu. Į šį paklausimą (pranešimą) įmonė
                            įsipareigoja atsakyti per 30 kalendorinių dienų nuo gauto paklausimo
                            (pranešimo) bei visų atsakyti būtinų dokumentų gavimo dienos.</p>
                        <p>Esant teisės aktuose numatytiems atvejams, duomenų valdytojas turi
                            teisę atsisakyti vykdyti aptartus duomenų subjekto prašymus. Pavyzdžiui,
                            jeigu reikia užtikrinti pavestų prievolių įgyvendinimą, užtikrinant
                            valstybės saugumą, gynybą, viešąją tvarką, nusikalstamų veikų prevenciją
                            ar baudžiamąjį persekiojimą, ginant svarbius valstybės ekonominius ar
                            finansinius interesus, asmenų teisių ir laisvių apsaugą.</p>
                        <p>Asmens duomenų subjektui nesutinkant su įmonės atsakymu ar jos
                            vykdomais veiksmais, duomenų subjektas turi teisę dėl to teikti skundą
                            kompetentingai valstybės institucijai – Valstybinei duomenų apsaugos
                            inspekcijai ar teismui.</p>
                    </div>
                </div>
            </div>
            <div class="clr">&nbsp;</div>
        </div>
    </div>


</div>