<div class="modal-content">

    <div class="modal-header">
        <div class="w-100 d-flex">
            <h3 class="accent text-center font-weight-bold">{{ header | translate }}</h3>
        </div>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true" (click)="closeNotification()">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        

        <p class="text-justify">{{ body | translate }}</p>

    </div>
    <div class="modal-footer">
        <app-button class="w-100 h-100 p-0 m-0" (click)="closeNotification()">{{ 'Component_Dialog_Close' | translate }}</app-button>
    </div>

</div>