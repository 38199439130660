<div class="overlay" [ngClass]="{'active': show}"></div>

<nav id="sidebar" class="background-primary container d-flex flex-column" [ngClass]="{'active' : show}" (swipe)="log($event)">
    <div class="sidebar-header row">
        <div class="col-7 offset-1 autopark-logo-light">
        </div>

        <div class="col-2 offset-1 d-flex custom__sidebar_close_button">
            <fa-icon class="fa-2x m-auto" [icon]="faTimes" (click)="close()"></fa-icon>
        </div>
    </div>

    <hr class="m-1"/>

    <ul class="row list-unstyled components custom__menu_list">
        <li class="col-12 menu-item dark">
            <a [routerLink]="'/'"><h2>{{ 'Menu_Home' | translate }}</h2></a>
        </li>
        <li class="col-12 menu-item dark">
            <a [routerLink]="'/search'" [fragment]="'filter'"><h2>{{ 'Menu_Search' | translate }}</h2></a>
        </li>
        <li class="col-12 menu-item dark">
            <a [routerLink]="'/about'" [fragment]="'contacts'"><h2>{{ 'Menu_Locations' | translate }}</h2></a>
        </li>
        <li class="col-12 menu-item dark">
            <a [routerLink]="'/faq'"><h2>{{ 'Menu_FAQ' | translate }}</h2></a>
        </li>
        <li class="col-12 menu-item dark">
            <a [routerLink]="'/about'"><h2>{{ 'Menu_About' | translate }}</h2></a>
        </li>
    </ul>

    <div class="row pl-4 mt-auto custom__menu_footer">
        <p class="col-12"><fa-icon class="pr-2" [icon]="faAt"></fa-icon>info@autopark.lt</p>
        <p class="col-12"><fa-icon class="pr-2" [icon]="faPhoneAlt"></fa-icon>+370 612 20222</p>
        <app-mobile-language-selection></app-mobile-language-selection>
    </div>
</nav>