
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { faBars, faSlidersH, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  faBars = faBars;
  faSlidersH = faSlidersH;
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;

  showMenu: boolean = false;
  

  scrollTop = 0;
  hideNav = false;


  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  showMenuClicked() {
    this.showMenu = true;
    this.onShowChange();
  }

  onShowChange() {
    if (this.showMenu) {
      this.renderer.addClass(document.body, 'noscroll');
    } else {
      this.renderer.removeClass(document.body, 'noscroll');
    }
  }

  
  @HostListener('document:scroll', ['$event'])
  onScroll(event) {
    this.hideNav = this.scrollTop < event.target.scrollingElement.scrollTop;
    this.scrollTop = event.target.scrollingElement.scrollTop;
  }
}
