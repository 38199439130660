<div class="container-fluid">
    <div class="row">
        <div class="col-6 background-accent d-none d-xl-flex">
            <div class="about-card-content pt-5 pb-5 mt-5 ml-auto mr-auto">
                <div class="display-5 font-weight-semi-bold primary">{{ 'Page_About_Title2' | translate }}</div>

                <p class="mt-4 primary">{{ 'Page_About_TitleDescription1' | translate }}</p>
                <p class="mt-4 primary">{{ 'Page_About_TitleDescription2' | translate }}</p>
                <p class="mt-4 primary">{{ 'Page_About_TitleDescription3' | translate }}</p>
            </div>
        </div>

        <div class="col-12 col-xl-6 filter-background">
            <div class="d-block d-xl-none h-200"></div>
        </div>

        <div class="col-12 d-block d-xl-none">
            <div class="about-card-content pb-5 mt-5 ml-auto mr-auto text-center">
                <div class="h1 font-weight-semi-bold">{{ 'Page_About_Title2' | translate }}</div>

                <p class="mt-4">{{ 'Page_About_TitleDescription1' | translate }}</p>
                <p class="mt-4">{{ 'Page_About_TitleDescription2' | translate }}</p>
                <p class="mt-4">{{ 'Page_About_TitleDescription3' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="container pt-5 pb-5">
        <div class="row pb-xl-5">
            <div class="w-100 p-4 accent text-center font-weight-medium">
                {{ 'Page_About_TeamTitle1' | translate }}
            </div>

            <h1 class="text-center w-100 font-weight-semi-bold">
                {{ 'Page_About_TeamTitle2' | translate }}
            </h1>
        </div>

        <div class="row">
            <div *ngFor="let manager of globalService.managers$ | async" class="col-12 col-xl-3 pb-3 pl-xl-1 pr-xl-1">
                <div class="card shadow">
                    <div class="card-body">
                        <small>{{ manager.jobPosition }}</small>
                        <h4 class="text-left">{{manager.name}}</h4>
                        <small class="w-100 d-block">Mob.:   {{manager.phone}}</small>
                        <small class="w-100 d-block">Email.: {{manager.email}}</small>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="row pb-4 pt-3">
            <h2 class="text-center w-100 font-weight-semi-bold">{{ 'Page_About_BankAccounts' | translate }}</h2>
        </div>
        <div class="row">
            <div class="col-12 col-xl-3 pb-3">
                <div class="card shadow">
                    <div class="card-body">
                        <h4 class="text-left">Swedbank</h4>
                        <small class="w-100 d-block">BIC: HABALT22</small>
                        <small class="w-100 d-block">A.S. LT17 7300 0101 1730 9222</small>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-3 pb-3">
                <div class="card shadow">
                    <div class="card-body">
                        <h4 class="text-left">Luminor</h4>
                        <small class="w-100 d-block">BIC: AGBLLT2X</small>
                        <small class="w-100 d-block">A.S. LT65 4010 0424 0159 3403</small>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-3 pb-3">
                <div class="card shadow">
                    <div class="card-body">
                        <h4 class="text-left">SEB</h4>
                        <small class="w-100 d-block">BIC: CBVILT2X</small>
                        <small class="w-100 d-block">A.S. LT10 7044 0600 0814 2680</small>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-3 pb-3">
                <div class="card shadow">
                    <div class="card-body">
                        
                        <h4 class="text-left">UAB Autopark</h4>
                        <small class="w-100 d-block">{{ 'Page_About_CompanyCode' | translate }} 125691822</small>
                        <small class="w-100 d-block">{{ 'Page_About_VATCode' | translate }} LT256918219</small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12 text-center">
            <p class="font-weight-medium accent mt-5">{{ 'Page_About_Best' | translate }}</p>
            <div class="display-5 font-weight-semi-bold mt-1">auto<span class="accent">park</span> {{ 'Page_About_InNumbers' | translate }}</div>
        </div>
    </div>
    <div class="container pt-5 pb-5">
        <div class="row pb-xl-5">
            
            <div class="col-12 col-xl-4">
                <div class="mb-3">
                    <div class="card shadow">
                        <div class="card-body p-5 d-flex flex-column text-center">
                            <div class="mock-square m-auto"></div>

                            <h4 class="mt-2">{{ 'Page_About_Number1_Title' | translate }}</h4>
                            <small class="mt-2">{{ 'Page_About_Number1_Description' | translate }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-4">
                <div class="mb-3">
                    <div class="card shadow">
                        <div class="card-body p-5 d-flex flex-column text-center">
                            <div class="mock-square m-auto"></div>

                            <h4 class="mt-2">{{ 'Page_About_Number2_Title' | translate }}</h4>
                            <small class="mt-2">{{ 'Page_About_Number2_Description' | translate }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-4">
                <div class="mb-3">
                    <div class="card shadow">
                        <div class="card-body p-5 d-flex flex-column text-center">
                            <div class="mock-square m-auto"></div>

                            <h4 class="mt-2">{{ 'Page_About_Number3_Title' | translate }}</h4>
                            <small class="mt-2">{{ 'Page_About_Number3_Description' | translate }}</small>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->

    <div id="contacts" name="contacts" #contacts></div>
    <div class="row" *ngFor="let lot of globalService.carLots$ | async; let i = index" [ngClass]="{'flex-xl-row-reverse': i % 2 == 0}">
        <div class="col-12 col-xl-6 background-accent h-450 d-flex">
            <div class="m-auto">
                <!-- <p class="primary custom__location_subtitle">{{ 'Page_About_Reach_Pre' | translate }} {{lot.name | uppercase}} {{ 'Page_About_Reach_Post' | translate }}</p> -->
                <div class="display-5 primary custom__location_title">{{lot.name}} {{ 'Page_About_Contacts' | translate }}</div>
    
                <div class="d-flex mt-3">
                    <div class="icon-wrapper d-flex mr-2">
                        <fa-icon class="m-auto fa-2x" [icon]="faEnvelope"></fa-icon>
                    </div>
                    <div class="ml-3 primary d-flex flex-column justify-content-evenly">
                        <span class="medium-small">{{ 'Page_About_Label_Email' | translate }}</span>
                        <span class="font-weight-semi-bold">{{lot.email}}</span>
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <div class="icon-wrapper d-flex mr-2">
                        <fa-icon class="m-auto fa-2x" [icon]="faPhone"></fa-icon>
                    </div>
                    <div class="ml-3 primary d-flex flex-column justify-content-evenly">
                        <span class="medium-small">{{ 'Page_About_Label_Phone' | translate }}</span>
                        <span class="font-weight-semi-bold">{{lot.phoneNo}}</span>
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <div class="icon-wrapper d-flex mr-2">
                        <fa-icon class="m-auto fa-2x" [icon]="faMapMarkerAlt"></fa-icon>
                    </div>
                    <div class="ml-3 primary d-flex flex-column justify-content-evenly">
                        <span class="medium-small">{{ 'Page_About_Label_Address' | translate }}</span>
                        <span class="font-weight-semi-bold">{{lot.address}}</span>
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <div class="icon-wrapper d-flex mr-2">
                        <fa-icon class="m-auto fa-2x" [icon]="faClock"></fa-icon>
                    </div>
                    <div class="ml-3 primary d-flex flex-column justify-content-evenly">
                        <span class="medium-small">{{ 'Page_About_Label_WorkHours' | translate }}</span>
                        <table>
                            <tr *ngFor="let hours of lot.workingHours">
                                <td><span class="font-weight-semi-bold">{{hours.days}}</span></td>
                                <td><span class="font-weight-semi-bold">: {{hours.hours | translate}}</span></td>  
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 col-xl-6 h-450 nopadding">
            <!-- Embed google maps -->
            <iframe [src]="lot.embedUrl | safe" class="h-450 w-100" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</div>