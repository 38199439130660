<div class="row">
    <div class="col-xl-8">
        <h3 class="m-3 col-12">{{ 'Page_Details_Features' | translate }}</h3>
        <div class="mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-block feature__columns">
                        <div *ngFor="let feature of vehicle?.features | translateAndSort:(globalService.vehicleFeatures$ | async)" class="small lh-lg col-12">
                            <fa-icon class="accent" [icon]="faCheck"></fa-icon>
                            {{ feature | idToName:(globalService.vehicleFeatures$ | async) | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
