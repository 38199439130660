import { Component, Input, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';
import { GlobalService } from 'src/app/services/global.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  faCheck = faCheck;

  @Input() vehicle: VehicleModel;

  constructor(
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
