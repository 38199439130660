import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-loan-calculator',
  templateUrl: './loan-calculator.component.html',
  styleUrls: ['./loan-calculator.component.css']
})
export class LoanCalculatorComponent implements OnInit {

  value: number = 50;
  enabled = true;

  change() {
  }

  faQuestionCircle = faQuestionCircle;

  private _price: number = 0;
  private interestRate: number = 1.02;

  @Input()
  set price(val: number) {
    if (val && val > 0) {
      this._price = val;
      this.downPaymentOptions = {
        floor: val*0.10,
        ceil: val*0.5,
        translate: (v, l) => `€ ${this.numberPipe.transform(v, '1.0-0', 'en-us').replace(',', ' ')}`
      }

      this.downPaymentValue = val*0.25;
      this.recalculatePaymentValues();
    }
  }

  monthlyPayment: number = 0;
  residualValuePayment: number = 0;

  downPaymentValue: number = 100;
  periodValue: number = 60;

  residualPercentage = {
    36: 0.45,
    48: 0.38,
    60: 0.32,
    72: 0.25,
    translate: (v, l) => ""
  };
  downPaymentOptions = {
    floor: 0,
    ceil: 10000,
    translate: (v, l) => ""
  };
  periodOptions = {
    floor: 36,
    ceil: 72,
    step: 12,
    translate: (v, l) => `${v} ` + this.translate.instant('Component_LoanCalculator_Months')
  };

  constructor(
    private numberPipe: DecimalPipe,
    private translate: TranslateService,
    private globalService: GlobalService,
  ) {
    globalService.settings$.subscribe(s => {
      if (s["LoanCalculatorInterest"] !== undefined) {
        this.interestRate = parseFloat(s.LoanCalculatorInterest)
        this.recalculatePaymentValues()
      }
    })
  }

  ngOnInit(): void {
  }

  onDownPaymentChange(e: any) {
    this.recalculatePaymentValues();
  }

  onPeriodChange(e: any) {
    this.recalculatePaymentValues();
  }

  recalculatePaymentValues() {
    this.monthlyPayment = ((this._price - this.downPaymentValue) / this.periodValue) * this.interestRate;
    this.residualValuePayment = (this._price * (1 - this.residualPercentage[this.periodValue]) - this.downPaymentValue)/ this.periodValue;
    this.monthlyPayment = this.monthlyPayment * this.interestRate;
  }
}
