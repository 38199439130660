import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { NotificationModel } from 'src/app/model/global/notification-model';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent extends SimpleModalComponent<NotificationModel, null> implements OnInit, NotificationModel {

  constructor(
    private translate: TranslateService,
  ) {
    super()
  }
  header: string;
  body: string;
  id: string;

  ngOnInit(): void {
  }

  openNotificationDialog() {
    // let modelRef = this.modalService.open(this.content, { centered: true });

    // modelRef.closed.subscribe(
    //   n => this.notificationClosed()
    // );
    
    // modelRef.dismissed.subscribe(
    //   n => this.notificationClosed()
    // );
  }

  closeNotification() {
    localStorage.setItem(this.id+this.translate.currentLang, "1");
    this.close();
  }


}
