
export class BaseFilter {
  take: number = 20;
  skip: number = 0;
  sortExpr: string = "";
  sortDesc: boolean = true;


  getFilter(): string {
    let fields = Object.getOwnPropertyNames(this);
    let filter = "";

    fields.forEach(f => {
      let val = ((this as any)[f]);
      if (val != undefined && val != "") {
        if (filter != "") {
          filter += "&";
        }
        filter += `${f}=${encodeURIComponent(val)}`
      }
    });

    return filter;
  }

  customParams(): any {
    let result: any = new Object();

    let baseFields = Object.getOwnPropertyNames(new BaseFilter());
    let fields = Object.getOwnPropertyNames(this);
    fields.forEach(f => {
      if (!baseFields.includes(f)) {
        let val = ((this as any)[f]);
        if (val != undefined && val != "") result[f] = val;
      }
    });
    return result;
  }
  
  public constructor(init?:Partial<BaseFilter>) {
    Object.assign(this, init);
  }
}