import { Component, Input, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {

  @Input() vehicle: VehicleModel;

  constructor() { }

  ngOnInit(): void {
  }

}
