<section class="accordion">
  <div *ngFor="let item of items;index as i"
    class="accordion__item" [class.disabled]="item.disabled" [class.active]="isExpanded(item, i)">
    <ng-container
      [ngTemplateOutlet]="(item?.customHeader?.templateRef || defaultHeader)"
      [ngTemplateOutletContext]="{$implicit: item, index: i, toggle: getToggleState(i)}"></ng-container>
    <div class="accordion__content medium" [class.expanded]="isExpanded(item, i)" [@contentExpansion]="isExpanded(item, i) ? 'expanded':'collapsed'">
      <ng-container *ngTemplateOutlet="item?.content?.templateRef"></ng-container>
    </div>
  </div>
</section>

<ng-template #defaultHeader let-item let-index="index">
  <header class="accordion__header"
    (click)="item.disabled ? {} :toggleState(index)">
    <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
    <button class="accordion__toggle-btn" [disabled]="item.disabled">
      <fa-icon class="accordion__toggle-open mt-auto mb-auto fa-2x accent" [icon]="faPlus"></fa-icon>
      <fa-icon class="accordion__toggle-close mt-auto mb-auto fa-2x accent" [icon]="faMinus"></fa-icon>
    </button>
  </header>
  <ng-template #defaultTitle>
    <h4 class="accordion__title">{{item?.title}}</h4>
  </ng-template>
</ng-template>
