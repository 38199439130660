import { Component, Input, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  @Input() vehicle: VehicleModel;

  constructor(
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
