import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectionItem } from "src/app/model/shared/selection-item.dto";
import { LINQUtil } from "../util/linq.util";

@Pipe({
  name: "translateAndSort"
})
export class TranslateAndSortPipe  implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

  transform(array: number[], items: SelectionItem[]): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    if (!items || !Array.isArray(items)) {
        return [];
    }

    let itemDict = Object.assign({}, ...items.map((x) => ({[x.id]: this.translate.instant(x.name)})));
    let translated = array.map(a => itemDict[a]);

    translated.sort((a: string, b: string) => {
        let aTrans = a.toLowerCase();
        let bTrans = b.toLowerCase();
        if (aTrans < bTrans) {
            return -1;
        } else if (aTrans > bTrans) {
            return 1;
        } else {
            return 0;
        }
    });
    return translated;
  }
}