 <div class="d-flex flex-column h-100">
     <div class="card car background-dark border-0 flex-grow-1 justify-content-between">
        <div #imgWrapper class="position-relative">
            <img role="button" class="w-100 rounded-top" [routerLink]="'/detail/' + link" [responsiveImage]="BASE_ADDR + '/' + vehicle.thumbnail" [referenceElement]="imgWrapper" [src]="BASE_ADDR + '/' + vehicle.thumbnail">
            <img role="button" *ngIf="vehicle.reserved" class="w-100 rounded-top reserved-stamp" [routerLink]="'/detail/' + link" [src]="'/assets/img/rezervuota.png'">
            <div role="button" *ngIf="vehicle.images.length > 0" (click)="showGallery()" class="primary img-count mb-2 mr-2 p-1"><fa-icon [icon]="faImages"></fa-icon><span class="pl-1">{{vehicle.images.length}}</span></div>
        </div>
    
        <div class="primary d-flex pl-2 pr-2 mt-2 flex-column flex-grow-1">
            <a class="car-name primary flex-grow-1 clickable-link" [routerLink]="'/detail/' + link">{{vehicle.title}}</a>
    
            <div class="spec-tag-list primary row d-inline-block pl-2 pr-2 flex-grow-1">
                <div *ngIf="vehicle.mileage" class="spec-tag pr-2 mt-2 pl-2 d-inline-block">
                    <fa-icon [icon]="faRoad"></fa-icon>
                    <span class="ml-1">{{vehicle.mileage | number:'1.0':'en-us' | replace:',':' '}}km</span>
                </div>
                <div *ngIf="vehicle.date" class="spec-tag pr-2 mt-2 pl-2 d-inline-block">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                    <span class="ml-1">{{vehicle.date}}</span>
                </div>
                <div *ngIf="vehicle.power" class="spec-tag pr-2 mt-2 pl-2 d-inline-block">
                    <fa-icon [icon]="faBolt"></fa-icon>
                    <span class="ml-1">{{vehicle.power | number:'1.0-0'}} kW</span>
                </div>
                <div *ngIf="vehicle.fuelType" class="spec-tag pr-2 mt-2 pl-2 d-inline-block">
                    <fa-icon [icon]="faGasPump"></fa-icon>
                    <span class="ml-1">{{vehicle.fuelType | idToName:(globalService.fuelTypes$ | async) | translate}}</span>
                </div>
                <div *ngIf="vehicle.transmissionType" class="spec-tag pr-2 mt-2 pl-2 d-inline-block">
                    <fa-icon [icon]="faCogs"></fa-icon>
                    <span class="ml-1">{{vehicle.transmissionType | idToName:(globalService.gearboxTypes$ | async) | translate}}</span>
                </div>
            </div>
    
    
            <div class="container-fluid">
                <div class="feature-tag-list mt-2 row">
                    <div *ngIf="vehicle.warrantyMileage > 0 || vehicle.warrantyPeriod > 0" class="col-4 pr-1 pl-1 small">
                        {{ 'Component_CarCard_Warranty' | translate }}
                    </div>
                    <div *ngIf="vehicle.warrantyPeriod > 0" class="col-4 feature-tag pl-1 pr-1 d-flex flex-column">
                        <div class="text-center ml-1 mr-1 background-accent primary rounded mt-auto mb-auto">{{vehicle.warrantyPeriod}} {{ 'Component_CarCard_Months' | translate }}</div>
                    </div>
                    <div *ngIf="vehicle.warrantyMileage > 0" class="col-4 feature-tag pl-1 pr-1 d-flex flex-column">
                        <div class="text-center ml-1 mr-1 background-accent primary rounded mt-auto mb-auto">{{vehicle.warrantyMileage}} km</div>
                    </div>
                </div>
            </div>
    
            <hr class="w-100 mt-3 mb-2 background-primary-gray"/>
    
            <div class="d-flex justify-content-end">
                <span *ngIf="vehicle.priceDiscounted" class="accent h4 font-weight-semi-bold mr-2">
                    {{vehicle.priceDiscounted | number:'1.0':'en-us' | replace:',':' '}} €
                </span>
                <span class="secondary-gray h4 font-weight-semi-bold" [ngClass]="{'strikethrough': vehicle.priceDiscounted}">
                    {{vehicle.price | number:'1.0':'en-us' | replace:',':' '}} €
                </span>
            </div>
        </div>
     </div>
     <div class="pb-3 flex-grow-0"></div>
 </div>