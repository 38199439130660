<div class="modal-content">
    <div class="modal-body" #sliderWrapper>
        
        <button type="button" class="close modal-close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <swiper style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
              [zoom]="true"
              [navigation]="true"
              [pagination]="{ clickable: true }"
              [keyboard]="{ enabled: true }"
              class="mySwiper">
  
              <ng-container  *ngFor="let img of images">
                  <ng-template swiperSlide>
                      <div class="swiper-zoom-container">
                          <img [responsiveImage]="BASE_ADDR + '/' + img" [referenceElement]="sliderWrapper" [src]="BASE_ADDR + '/' + img" />
                      </div>
                  </ng-template>
              </ng-container>
          </swiper>

    </div>
</div>