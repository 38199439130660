import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faAt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  faTimes = faTimes;
  faAt = faAt;
  faPhoneAlt = faPhoneAlt;

  @Input() show: boolean = false;
  @Output() showChange = new EventEmitter<boolean>();

  constructor(
    router: Router
  ) {
    router.events.subscribe(e => this.close());
  }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
  }

  log(e: any) {
  }
}
