<div class="overlay" [ngClass]="{'active': filterShow}"></div>

<div class="filter__header w-100 d-block d-xl-none" [class.hide-nav]="hideNav || filterShow">
    <div class="header-row w-100 background-primary d-flex">
        <button class="filter-button mt-auto mb-auto ml-4" (click)="showFilter()">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2V4H10V2H0ZM4 6V8H0V10H4V12H6V6H4ZM18 10V8H8V10H18ZM12 6H14V4H18V2H14V0H12V6Z" fill="#C8102E"/>
            </svg>
            <span>Filter</span></button>                
    </div>
</div>

<div id="sidebar" class="background-primary pr-2 pl-2 d-block d-xl-none" [ngClass]="{'active': filterShow}">
    <button class="filter-close-button"><fa-icon [icon]="faTimes" (click)="close()"></fa-icon></button>
    <app-sidebar-filter class="scrollable pb-3" [filter]="filter" (searchClicked)="onSearchClicked()"></app-sidebar-filter>
</div>

<div class="spacer"></div>

<div class="container expanded mt-5">
    <div class="row">
        
        <!-- Filter -->
        <div class="col-3 custom__desktop_filter">
            <div class="card mr-5">
                <div class="card-body">
                    <app-sidebar-filter [filter]="filter"></app-sidebar-filter>
                </div>
            </div>
        </div>
        
        <!-- Results -->
        <div class="col-12 col-xl-9">

            <div class="col-12 mb-2 d-flex justify-content-end nopadding_horizontal">
                <app-pagination *ngIf="totalCount > perPage"
                    [perPage]="perPage"
                    [totalCount]="totalCount"
                    [page]="filter.page"
                    (nextClicked)="onNextClicked()"
                    (prevClicked)="onPrevClicked()">
                </app-pagination>
            </div>


            <!-- Result cards if found -->
            <div *ngIf="vehicles.length > 0" class="card-container">
                <div *ngFor="let vehicle of vehicles" class="col-12 col-sm-4 col-xl-3 pl-xl-1 pr-xl-1">
                    <app-car-card [vehicle]="vehicle"></app-car-card>
                </div>
            </div>

            <!-- No result found -->
            <div *ngIf="vehicles.length == 0 && successfulyLoaded" class="d-flex h-75">
                <div class="m-auto">
                    <h1 class="text-center"><fa-icon [icon]="faTimes" class="fa-3x"></fa-icon></h1>
                    <h1 class="text-center">{{ 'Page_Search_NothingFound' | translate }}</h1>
                </div>
            </div>

            <div class="spacer">
            </div>
            
            <div class="col-12 mb-2 d-flex justify-content-end nopadding_horizontal">
                <app-pagination *ngIf="totalCount > perPage"
                    [perPage]="perPage"
                    [totalCount]="totalCount"
                    [page]="filter.page"
                    (nextClicked)="onNextClicked()"
                    (prevClicked)="onPrevClicked()">
                </app-pagination>
            </div>
        </div>
    </div>
</div>  