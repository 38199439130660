<div class="pagination-wrapper d-flex rounded">

    <div [ngClass]="{'visible': page > 1, 'invisible': page == 1}" class="pagination-button primary d-flex gradient-accent rounded" (click)="onPrevClick()">
        <fa-icon class="h5 m-auto custom__pagination_prev" [icon]="faChevronLeft"></fa-icon>
    </div>

    <p class="medium-small flex-grow-1 m-auto text-center custom__pagination_text">
        <span class="custom__pagination_current_page">{{page}}</span>
        <span class="custom__pagination_current_slash">/</span>
        <span class="custom__pagination_total_page">{{totalPageCount}}</span>
    </p>

    <div [ngClass]="{'visible': page < totalPageCount, 'invisible': page == totalPageCount}"
        class="pagination-button primary d-flex gradient-accent rounded" (click)="onNextClick()">
        <fa-icon class="h5 m-auto custom__pagination_next" [icon]="faChevronRight"></fa-icon>
    </div>

</div>