<div class="menu-wrapper">
    <app-menu class="d-xl-none" [(show)]="showMenu" (showChange)="onShowChange()" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')"></app-menu>
</div>

<div class="header-row w-100 background-primary">
    <div class="container-fluid">
        <div class="row d-flex custom__header">
            <div class="custom__header_logo">
                <a [routerLink]="'/'">
                    <img src="assets/img/autopark-logo.svg"/>
                </a>
            </div>

            <div class="header-menu d-none d-xl-flex mt-auto mb-auto">

                <a class="header-menu-item" [routerLink]="'/'">
                    {{ 'Menu_Home' | translate }}
                </a>
                <a class="header-menu-item" [routerLink]="'/search'">
                    {{ 'Menu_Search' | translate }}
                </a>
                <a class="header-menu-item" [routerLink]="'/about'" [fragment]="'contacts'">
                    {{ 'Menu_Locations' | translate }}
                </a>
                <a class="header-menu-item" [routerLink]="'/faq'">
                    {{ 'Menu_FAQ' | translate }}
                </a>
                <a class="header-menu-item" [routerLink]="'/about'">
                    {{ 'Menu_About' | translate }}
                </a>

            </div>

            <app-language-selection class="ml-auto"></app-language-selection>

            <div class="d-xl-none dark">
                <fa-icon class="fa-2x m-auto menu_icon" (click)="showMenuClicked()" [icon]="faBars"></fa-icon>
            </div>
        </div>
    </div>
</div>