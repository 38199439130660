<div class="container pb-2" *ngIf="vehicle">
    <div class="row pl-4 pr-4 pt-4">
        <a class="accent clickable-link" (click)="location.back()">&lt; {{ 'Page_Details_Back' | translate }}</a>
    </div>

    <app-title [vehicle]="vehicle"></app-title>

    <!-- Desktop layout -->

    <ng-container *ngIf="!(isMobile$ | async)">
        <div class="row">

            <!-- Left column -->
            <div #swiperWrapper class="col-12 col-xl-8" *ngIf="vehicle">
                <swiper style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff" [navigation]="true"
                    [pagination]="{
                    clickable: true
                    }" class="mySwiper">

                    <ng-container *ngFor="let img of vehicle.images">
                        <ng-template swiperSlide>
                            <div class="swiper-zoom-container">
                                <img [responsiveImage]="BASE_ADDR + '/' + img" [referenceElement]="swiperWrapper" [src]="BASE_ADDR + '/' + img"  (click)="openModal()"/>
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
                <!-- <app-modal-gallery [(show)]="showModal" [images]="vehicle.images"></app-modal-gallery> -->

                <app-detail [vehicle]="vehicle"></app-detail>
                

                <app-description *ngIf="vehicle.comment" [vehicle]="vehicle"></app-description>
            </div>

            <!-- Right column -->
            <div class="col-12 col-xl-4">

                <app-featured [vehicle]="vehicle"></app-featured>
                
                <app-loan-calculator *ngIf="vehicle.price && vehicle.showLoanCalculator && !vehicle.sold" class="d-none d-xl-block mt-3" [price]="vehicle?.price"></app-loan-calculator>

                <div class="row" *ngIf="vehicle.managers?.length > 0 && !vehicle.sold">
                    <a class="gradient-accent w-100 m-3 rounded text-center p-2 d-none d-xl-block custom__button"
                    [href]="'mailto:'+vehicle.managers[0].email">
                        <span class="primary h5">{{ 'Page_Details_BuyNow' | translate }} {{vehicle.managers[0].phoneNo}}</span>
                    </a>
                </div>

                <app-car-lot *ngIf="!vehicle.sold" [vehicle]="vehicle"></app-car-lot>

            </div>
        </div>

        <app-features *ngIf="vehicle.features.length > 0" [vehicle]="vehicle"></app-features>
    </ng-container>

    <!-- Mobile layout -->

    <ng-container *ngIf="(isMobile$ | async)">        
        <div class="row">
            <div #swiperWrapper class="col-12" *ngIf="vehicle">
                <swiper style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff" [navigation]="true"
                    [pagination]="{
                    clickable: true
                    }" class="mySwiper">

                    <ng-container *ngFor="let img of vehicle.images">
                        <ng-template swiperSlide>
                            <div class="swiper-zoom-container">
                                <img [responsiveImage]="BASE_ADDR + '/' + img" [referenceElement]="swiperWrapper" [src]="BASE_ADDR + '/' + img" (click)="openModal()"/>
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
                <app-modal-gallery [(show)]="showModal" [images]="vehicle.images"></app-modal-gallery>
            </div>

            <div class="col-12 mt-3">
                <app-featured [vehicle]="vehicle"></app-featured>
            </div>

            <div class="col-12">
                <app-detail [vehicle]="vehicle"></app-detail>
            </div>

            <div class="col-12">
                <app-car-lot [vehicle]="vehicle"></app-car-lot>
            </div>

            <div class="col-12">
                <app-description *ngIf="vehicle.comment" [vehicle]="vehicle"></app-description>
            </div>

            <div class="col-12">
                <app-features *ngIf="vehicle.features.length > 0" [vehicle]="vehicle"></app-features>
            </div>

            <div class="col-12 mt-3">
                <app-loan-calculator *ngIf="vehicle.price && vehicle.showLoanCalculator" [vehicle]="vehicle" [price]="vehicle?.price"></app-loan-calculator>
            </div>
            
            <div class="col-12" *ngIf="vehicle.managers?.length > 0">
                <a class="gradient-accent w-100 mt-3 rounded text-center p-2 d-block d-xl-none custom__button"
                    [href]="'tel:'+vehicle.managers[0].phoneNo">
                    <span class="primary h5">{{ 'Page_Details_BuyNow' | translate }} {{vehicle.managers[0].phoneNo}}</span>
                </a>
            </div>            
        </div>
    </ng-container>
</div>
