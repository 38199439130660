import { Component, Input, OnInit } from '@angular/core';
import { VehicleModel } from 'src/app/model/vehicle/vehicle.dto';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  @Input() vehicle: VehicleModel;

  constructor() { }

  ngOnInit(): void {
  }

}
