<div class="background-light-gray custom__footer_container">
  <div class="custom__footer">
    <div class="custom__footer_column custom__footer_logo_column">
        <div class="custom__footer_logo">
            <img src="assets/img/autopark-logo.svg" />
        </div>
        <div class="small">© 2010-{{copyrightYear}} UAB Autopark.</div>
        <a class="small" role="button" href="mailto: info@autopark.lt">info@autopark.lt</a>
        <a class="small" role="button" href="tel: +370 612 20222">+370 612 20222</a>
        <div class="small">{{ 'Component_Footer_Rights' | translate }}</div>
    </div>

    <div class="custom__footer_column">
        <h4 class="text-left">Autopark</h4>
        <a class="small" [routerLink]="'/'">{{ 'Menu_Home' | translate }}</a>
        <a class="small" routerLink="/search">{{ 'Menu_Search' | translate }}</a>
    </div>
    <div class="custom__footer_column">
        <h4 class="text-left">{{ 'Menu_Contact' | translate }}</h4>
        <div role="button" class="small" routerLink="/about" fragment="contacts" *ngFor="let lot of globalService.carLots$ | async">{{lot.address}}</div>
    </div>
    <div class="custom__footer_column">
        <h4 class="text-left">{{ 'Menu_About' | translate }}</h4>
        <a class="small" routerLink="/about">{{ 'Menu_About' | translate }}</a>
        <a class="small" routerLink="/about" fragment="contacts">{{ 'Menu_Contact' | translate }}</a>
        <a class="small" routerLink="/privacy-policy">{{ 'Menu_Privacy' | translate }}</a>
    </div>
  </div>
</div>
