import { Component, OnInit } from '@angular/core';
import { faRoad, faCheckCircle, faBolt, faGasPump, faCogs, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faSlidersH, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { VehicleService } from 'src/app/services/vehicle.service';
import { BaseFilter } from 'src/app/model/shared/base.filter';
import { VehicleListModel } from 'src/app/model/vehicle/vehicle-list.dto';
import { VehicleListFilter } from 'src/app/model/vehicle/vehicle-list.filter';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  faRoad = faRoad;
  faCheckCircle = faCheckCircle;
  faBolt = faBolt;
  faGasPump = faGasPump;
  faCogs = faCogs;
  faMapMarkerAlt = faMapMarkerAlt;
  faCalendar = faCalendar;

  faSlidersH = faSlidersH;
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;
  
  vehicles: VehicleListModel[] = [];
  vehicleFilter = new VehicleListFilter({sortExpr: 'id', sortDesc: true});

  constructor(
    private meta: Meta,
    private vehicleService: VehicleService
  ) {
    this.meta.addTags([
      {name: 'description', content: 'Autopark parduoda aukštos kokybės naudotus automobilius ir suteikia garantiją!'},
      {name: 'keywords', content: 'autopark,parduoda,aukštos,kokybės,naudotus,automobilius,suteikia,garantiją,daugiau,apie,paslaugas'}
    ]); 
  }

  ngOnInit(): void {
    this.vehicleService.list(new BaseFilter({sortExpr: 'id', sortDesc: true}))
    .subscribe(v => {
      this.vehicles = v.list;
    })
  }

}
