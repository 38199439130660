import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";
import { ManufacturerModelItem } from "src/app/model/global/manufacturer-model-item.model";
import { CarLotModel } from "src/app/model/global/car-lot.model";
import { SelectionItem } from "src/app/model/shared/selection-item.dto";
import { ManagerModel } from "../model/global/manager.model";
import { BASE_ADDR } from "../app.component";

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    path: string = '/api/global/'
    cache: any = {}

    get manufacturerModelList$() { return this.getList<ManufacturerModelItem>("manufacturer-models") }
    get vehicleState$() { return this.getList<SelectionItem>("vehicle-states") }
    get translations$() { return this.getList<SelectionItem>("translations") }
    get bodyTypes$() { return this.getList<SelectionItem>("body-types") }
    get climateControls$() { return this.getList<SelectionItem>("climate-controls") }
    get colors$() { return this.getList<SelectionItem>("colors") }
    get countries$() { return this.getList<SelectionItem>("countries") }
    get drivenWheels$() { return this.getList<SelectionItem>("driven-wheels") }
    get fuelTypes$() { return this.getList<SelectionItem>("fuel-types") }
    get gearboxTypes$() { return this.getList<SelectionItem>("gearbox-types") }
    get doorsTypes$() { return this.getList<SelectionItem>("doors-types") }
    get carLots$() { return this.getList<CarLotModel>("car-lots") }
    get vehicleClassifiers$() { return this.getList<SelectionItem>("vehicle-classifiers") }
    get vehicleFeatures$() { return this.getList<SelectionItem>("vehicle-features") }
    get managers$() { return this.getList<ManagerModel>("managers") }
    get settings$() { return this.getDict("settings") }

    constructor(private http: HttpClient) {
    }

    public prefetch() {
        this.manufacturerModelList$.subscribe(r => {});
    }

    private getList<T>(name: string): Observable<T[]> {
        if (this.cache[name]) {
            return this.cache[name];
        }

        return this.fetchList<T>(name);
    }

     fetchList<T>(name: string): Observable<T[]> {
         this.cache[name] = this.http.get<T[]>(`${BASE_ADDR}${this.path}${name}`)
            .pipe(
                shareReplay(1),
                catchError(err => {
                    delete this.cache[name];
                    return EMPTY;
                })
            );

         return this.cache[name];
     }

     private getDict(name: string): Observable<{ [id: string] : string }> {
        if (this.cache[name]) {
            return this.cache[name];
        }

        return this.fetchDict(name);
     }

     fetchDict(name: string): Observable<{ [id: string] : string }> {
         this.cache[name] = this.http.get<{ [id: string] : string }>(`${BASE_ADDR}${this.path}${name}`)
            .pipe(
                shareReplay(1),
                catchError(err => {
                    delete this.cache[name];
                    return EMPTY;
                })
            );

         return this.cache[name];
     }
}
