import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faMapMarkerAlt = faMapMarkerAlt;
  faClock = faClock;

  constructor(
    private meta: Meta,
    public globalService: GlobalService,
    private router: Router
  ) {

    this.meta.addTags([
      {name: 'description', content: 'Autopark parduoda aukštos kokybės naudotus automobilius ir suteikia garantiją!'},
      {name: 'keywords', content: 'autopark,parduoda,aukštos,kokybės,naudotus,automobilius,suteikia,garantiją,daugiau,apie,paslaugas'}
    ]); 
    
    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll('#' + event.anchor);
        }, 100);
      }
    });
  }

  ngOnInit(): void {
  }


  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else {
      targetElement.scrollIntoView();
    }
  }
}
