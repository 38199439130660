import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Subject, takeUntil } from 'rxjs';
import { BASE_ADDR } from 'src/app/app.component';
import { ModalGalleryModel } from 'src/app/model/shared/modal-gallery.model';
import SwiperCore, {Zoom, Navigation, Pagination, Keyboard} from 'swiper'

SwiperCore.use([Zoom, Navigation, Pagination, Keyboard]);

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.css']
})
export class ModalGalleryComponent extends SimpleModalComponent<ModalGalleryModel, null> implements ModalGalleryModel, OnInit, OnDestroy {
  BASE_ADDR = BASE_ADDR;
  
  private ngUnsubscribe = new Subject();

  @ViewChild('content') modalContent!: TemplateRef<any>;

  images: string[] = [];

  @Input()
  set show(val: boolean) {
    if (val) this.open();
  }
  @Output()
  showChange = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2, private elementRef: ElementRef
  ) {
    super()
  }

  ngOnInit(): void {
    this.calcWidth();
  }

  ngOnDestroy() {
      this.ngUnsubscribe.next(undefined);
      this.ngUnsubscribe.complete();
  }


  calcWidth(): void {
    let width = window.innerHeight * 0.95 * (4 / 3);

    if (width > window.innerWidth)
    {
      width = window.innerWidth * 0.98;
    }

    this.renderer.setStyle(this.elementRef.nativeElement.childNodes[0], "width", `${width}px`)
    this.renderer.setStyle(this.elementRef.nativeElement.childNodes[0].childNodes[0], "width", `${width}px`)
  }

  open() {
    // if (!this.modalService.hasOpenModals()) {
    //   this.modal = this.modalService.open(this.modalContent, { size: 'xl', centered: true });

    //   this.modal.closed
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe(r => this.showChange.emit(false));
    //   this.modal.dismissed
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe(r => this.showChange.emit(false));
    // }
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.calcWidth()
    }
}
