//import 'hammerjs'
import 'mousetrap'

// import from '@egjs/hammerjs'

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, BASE_ADDR } from './app.component';
import { LayoutComponent } from './core/layout/layout.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DropdownComponent } from './core/component/dropdown/dropdown.component';
import { HomeComponent } from './core/page/home/home.component';
import { DetailsComponent } from './core/page/details/details.component';
import { MenuComponent } from './core/component/menu/menu.component';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { StaticComponent } from './core/page/static/static.component';
import { HeaderComponent } from './core/component/header/header.component';
import { FilterComponent } from './core/component/filter/filter.component';
import { ButtonComponent } from './core/component/button/button.component';
import { CategoriesComponent } from './core/component/categories/categories.component'
import { SwiperModule } from 'swiper/angular';
import { CarCardComponent } from './core/component/car-card/car-card.component';
import { SidebarFilterComponent } from './core/component/sidebar-filter/sidebar-filter.component';
import { SearchComponent } from './core/page/search/search.component';
import { VehicleService } from './services/vehicle.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { GlobalService } from './services/global.service';

import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeCA from '@angular/common/locales/ca'
import { ReplacePipe } from './core/pipe/replace.pipe';
import { IdToNamePipe } from './core/pipe/id-to-name.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DefaultPipe } from './core/pipe/default.pipe';
import { LanguageSelectionComponent } from './core/component/language-selection/language-selection.component';
import { FooterComponent } from './core/component/footer/footer.component';
import { LoanCalculatorComponent } from './core/component/loan-calculator/loan-calculator.component';

import { FaqComponent } from './core/page/faq/faq.component';
import { AccordionComponent } from './core/component/accordion/accordion.component';
import { AboutUsComponent } from './core/page/about-us/about-us.component';
import { PaginationComponent } from './core/component/pagination/pagination.component';
import { SafePipe } from './core/pipe/safe.pipe';
import { MobileLanguageSelectionComponent } from './core/component/mobile-language-selection/mobile-language-selection.component';
import { DualDropdownComponent } from './core/component/dual-dropdown/dual-dropdown.component';
import { ModalGalleryComponent } from './core/component/modal-gallery/modal-gallery.component';
import { TitleComponent } from './core/component/details/title/title.component';
import { GalleryComponent } from './core/component/details/gallery/gallery.component';
import { DetailComponent } from './core/component/details/detail/detail.component';
import { DescriptionComponent } from './core/component/details/description/description.component';
import { FeaturedComponent } from './core/component/details/featured/featured.component';
import { FeaturesComponent } from './core/component/details/features/features.component';
import { CarLotComponent } from './core/component/details/car-lot/car-lot.component';
import { RouterXModule } from '@bespunky/angular-zen/router-x';
import { RedirectorComponent } from './core/infrastructure/redirector/redirector.component';
import { FatSliderComponent } from './core/component/fat-slider/fat-slider.component';
import { FormsModule } from '@angular/forms';
import { NotificationDialogComponent } from './core/component/notification-dialog/notification-dialog.component';
import { TranslateAndSortPipe } from './core/pipe/sort-by-translation.pipe';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';

import { ClickOutsideDirective } from './core/directive/click-outside.directive';
import { AccordionModule } from './core/shared/accordion/accordion.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResponsiveImageDirective } from './core/directive/responsive-image.directive';

registerLocaleData(localeCA);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${BASE_ADDR}/api/translate/`, '');
}




@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DropdownComponent,
    HomeComponent,
    DetailsComponent,
    MenuComponent,
    StaticComponent,
    HeaderComponent,
    FilterComponent,
    ButtonComponent,
    CategoriesComponent,
    CarCardComponent,
    SidebarFilterComponent,
    SearchComponent,
    ReplacePipe,
    IdToNamePipe,
    DefaultPipe,
    LanguageSelectionComponent,
    FooterComponent,
    LoanCalculatorComponent,
    FaqComponent,
    AccordionComponent,
    AboutUsComponent,
    PaginationComponent,
    SafePipe,
    MobileLanguageSelectionComponent,
    DualDropdownComponent,
    ModalGalleryComponent,
    TitleComponent,
    GalleryComponent,
    DetailComponent,
    DescriptionComponent,
    FeaturedComponent,
    FeaturesComponent,
    CarLotComponent,
    RedirectorComponent,
    FatSliderComponent,
    NotificationDialogComponent,
    TranslateAndSortPipe,
    ClickOutsideDirective,
    ResponsiveImageDirective,
  ],
  exports: [
    DefaultPipe,
    SafePipe,
    TranslateAndSortPipe,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FontAwesomeModule,
    NgxFlagIconCssModule,
    SwiperModule,
    HttpClientModule,
    RouterXModule.forRoot(),
    FormsModule,
    SimpleModalModule.forRoot({container: 'modal-container'}, {...defaultSimpleModalOptions, ...{
      closeOnEscape: true,
      closeOnClickOutside: true,
      animationDuration: 300,
      autoFocus: true
    }}),
    AccordionModule,
    BrowserAnimationsModule,
  ],
  providers: [
    VehicleService,
    GlobalService,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationDialogComponent
  ],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faBars)
  }
}