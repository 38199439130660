<div class="home-wrapper background-light-gray">
    <app-filter [(filter)]="vehicleFilter"></app-filter>
    <app-categories></app-categories>


    <div class="container">
        <div class="row mt-4 mb-5">
            <h2 class="w-100 accent font-weight-bold text-center">{{ 'Page_Home_New' | translate }}</h2>
        </div>
        <div class="row">

            <div *ngFor="let vehicle of vehicles" class="col-12 col-sm-4 col-xl-3 pl-xl-1 pr-xl-1">
                <app-car-card [vehicle]="vehicle"></app-car-card>
            </div>
        </div>

        <div class="row mt-4 mb-4">
            <app-button class="margin_auto" routerLink="/search">{{ 'Page_Home_LoadMore' | translate }}</app-button>
        </div>

    </div>
</div>