import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { APP_RESP } from 'server';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.css']
})
export class RedirectorComponent implements OnInit {
  // ngOnInit(): void {
  //   throw new Error('Method not implemented.');
  // }


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  @Optional() @Inject(RESPONSE) private response: any) { }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.redirect(301, "/");
    }
  }

}
