import { BaseFilter } from '../shared/base.filter'

export class VehicleListFilter extends BaseFilter {
    manufacturerId?: number;
    modelId?: number;
    priceFrom?: number;
    priceTo?: number;
    yearFrom?: number;
    yearTo?: number;
    mileageFrom?: number;
    mileageTo?: number;
    transmisionType?: number;
    powerFrom?: number;
    powerTo?: number;
    vehicleType?: number;
    bodyType?: number;
    carLotId?: number;
    fuelType?: number;
    vehicleStateId?: number;

    page?: number;

    public constructor(init?:Partial<BaseFilter>) {
        super(init);
        if (this.page === undefined) this.page = 1;
        else if (typeof(this.page) == 'string') this.page = parseInt(this.page);
    }
}