<div class="row d-flex p-4">

    <div class="col-12 col-xl-8 d-flex flex-column">
        <div class="row pr-4 pt-2 d-flex flex-column">
            <h2 class="font-weight-bold text-left">{{vehicle?.title}}</h2>
            <small class="accent text-left font-weight-bold" *ngIf="vehicle.arrivalDate">
                {{ 'Page_Details_ExpectedArrival' | translate }} {{vehicle.arrivalDate | date:'yyyy-MM-dd'}}
            </small>
            <small class="accent text-left font-weight-bold h4" *ngIf="vehicle.reserved">
                {{ 'Page_Details_Reserved' | translate }}
            </small>
            <large class="accent text-left font-weight-bold h4" *ngIf="vehicle.sold">
                {{ 'Page_Details_Sold' | translate }}
            </large>
        </div>
    </div>

    <div *ngIf="vehicle.price" class="col-12 col-xl-4 row d-flex flex-column pt-2 text-xl-center text-left">
        <h2 class="accent font-weight-bold mb-0">{{vehicle?.price | number:'1.0':'en-us' | replace:',':' '}} €</h2>
        <small class="" *ngIf="vehicle.priceForExport && vehicle.price != vehicle.priceForExport">{{vehicle.priceForExport | number:'1.0':'en-us' | replace:',':' '}} eur {{
            'Page_Details_ForExport' | translate }}</small>
    </div>
</div>